/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./workhour-dropdown-column-manipulator/workhour-dropdown-column-manipulator.component.ngfactory";
import * as i2 from "angular2-web-worker/dist/web-worker.service";
import * as i3 from "./workhour-dropdown-column-manipulator/workhour-dropdown-column-manipulator.component";
import * as i4 from "../../../../services/permission/permission.service";
import * as i5 from "../../../services/entity-data-store.service";
import * as i6 from "./workhour-leased-employee-autocomplete/workhour-leased-employee-autocomplete.component.ngfactory";
import * as i7 from "./workhour-leased-employee-autocomplete/workhour-leased-employee-autocomplete.component";
import * as i8 from "../../../../services/generic-crud.service";
import * as i9 from "./workhour-assignment-autocomplete/workhour-assignment-autocomplete.component.ngfactory";
import * as i10 from "./workhour-assignment-autocomplete/workhour-assignment-autocomplete.component";
import * as i11 from "./workhour-leased-employee-next-button/workhour-leased-employee-next-button.component.ngfactory";
import * as i12 from "./workhour-leased-employee-next-button/workhour-leased-employee-next-button.component";
import * as i13 from "../../../../../core/message/message-growl.service";
import * as i14 from "@ngx-translate/core";
import * as i15 from "@angular/common";
import * as i16 from "./custom-filter-class.component";
var styles_CustomFilterClassComponent = [];
var RenderType_CustomFilterClassComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomFilterClassComponent, data: {} });
export { RenderType_CustomFilterClassComponent as RenderType_CustomFilterClassComponent };
function View_CustomFilterClassComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-workhour-dropdown-column-manipulator", [], null, null, null, i1.View_WorkhourDropdownColumnManipulatorComponent_0, i1.RenderType_WorkhourDropdownColumnManipulatorComponent)), i0.ɵprd(512, null, i2.WebWorkerService, i2.WebWorkerService, []), i0.ɵdid(2, 4243456, [[1, 4], ["filterComponent", 4]], 0, i3.WorkhourDropdownColumnManipulatorComponent, [i4.PermissionService, i5.EntityDataStoreService, i2.WebWorkerService], { component: [0, "component"], filterItem: [1, "filterItem"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component; var currVal_1 = _co.filterItem; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CustomFilterClassComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-workhour-leased-employee-autocomplete", [], null, null, null, i6.View_WorkhourLeasedEmployeeAutocompleteComponent_0, i6.RenderType_WorkhourLeasedEmployeeAutocompleteComponent)), i0.ɵdid(1, 114688, [[1, 4], ["filterComponent", 4]], 0, i7.WorkhourLeasedEmployeeAutocompleteComponent, [i4.PermissionService, i5.EntityDataStoreService, i8.GenericCrudService, i0.ChangeDetectorRef], { component: [0, "component"], filterItem: [1, "filterItem"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component; var currVal_1 = _co.filterItem; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomFilterClassComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-workhour-assignment-autocomplete", [], null, null, null, i9.View_WorkhourAssignmentAutocompleteComponent_0, i9.RenderType_WorkhourAssignmentAutocompleteComponent)), i0.ɵdid(1, 114688, [[1, 4], ["filterComponent", 4]], 0, i10.WorkhourAssignmentAutocompleteComponent, [i4.PermissionService, i5.EntityDataStoreService, i8.GenericCrudService], { component: [0, "component"], filterItem: [1, "filterItem"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component; var currVal_1 = _co.filterItem; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomFilterClassComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-workhour-leased-employee-next-button", [], null, null, null, i11.View_WorkhourLeasedEmployeeNextButtonComponent_0, i11.RenderType_WorkhourLeasedEmployeeNextButtonComponent)), i0.ɵdid(1, 49152, [[1, 4], ["filterComponent", 4]], 0, i12.WorkhourLeasedEmployeeNextButtonComponent, [i8.GenericCrudService, i13.MessageGrowlService, i14.TranslateService], { component: [0, "component"], filterItem: [1, "filterItem"], genericFilterComponent: [2, "genericFilterComponent"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component; var currVal_1 = _co.filterItem; var currVal_2 = _co.genericFilterComponent; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CustomFilterClassComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { filterComponent: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomFilterClassComponent_1)), i0.ɵdid(2, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomFilterClassComponent_2)), i0.ɵdid(4, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomFilterClassComponent_3)), i0.ɵdid(6, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomFilterClassComponent_4)), i0.ɵdid(8, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.filterItem.customFilterClassName === "workhourDropdownColumnManipulator"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.filterItem.customFilterClassName === "workhourLeasedEmployeeAutocomplete"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.filterItem.customFilterClassName === "workhourAssignmentAutocomplete"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.filterItem.customFilterClassName === "workhourLeasedEmployeeNextButton"); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_CustomFilterClassComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-filter-class", [], null, null, null, View_CustomFilterClassComponent_0, RenderType_CustomFilterClassComponent)), i0.ɵdid(1, 114688, null, 0, i16.CustomFilterClassComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomFilterClassComponentNgFactory = i0.ɵccf("app-custom-filter-class", i16.CustomFilterClassComponent, View_CustomFilterClassComponent_Host_0, { component: "component", filterItem: "filterItem", genericFilterComponent: "genericFilterComponent" }, {}, []);
export { CustomFilterClassComponentNgFactory as CustomFilterClassComponentNgFactory };
