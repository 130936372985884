import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {GenericCrudService} from '../../services/generic-crud.service';
import {DoubleClickAware, ModuleElement} from '../../services/module/module-element';
import {FieldMetadataGrid} from '../../services/module/module-element-field-metadata-grid';
import {Module} from '../../services/module/module';
import {LocationService} from '../../services/location.service';
import {ApiBuilderService} from '../../services/api.builder.service';
import {ModulesStateService} from './modules-state.service';
import {EntityDataStoreService} from './entity-data-store.service';
import {GenericElementAbstract} from '../elements/generic-element-abstract.component';
import {Subscription} from 'rxjs';
import {ToolbarItemCheckService} from '../elements/generic-toolbar/services/check/toolbar-item-check.service';
import {ModuleState} from 'app/shared/content-renderer/services/module-state';
import {ElementsStateService} from './elements-state.service';
import {ElementsStackService} from './elements-stack.service';
import {RuntimeFlagName} from './ElementContext';
import {AbstractGenericGridComponent} from '../elements/abstract-generic-grid.component';
import {DataSharingService} from './data-sharing.service';
import {Parameter} from './data';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {GenericDialogModuleService, GenericDialogOptions} from '../elements/generic-dialog/service/generic-dialog-module.service';
import {ModuleRouter} from './navigation/module-router.service';
import {Router} from '@angular/router';
import {GenericFullCalendarComponent} from '../elements/generic-full-calendar/generic-full-calendar.component';

@Injectable()
export class DoubleClickService implements OnInit, OnDestroy {

  private component: AbstractGenericGridComponent| GenericFullCalendarComponent = null;
  // private grid: AbstractGenericGridComponent = null;
  // private calendar: GenericFullCalendarComponent = null;
  private form: FormViewerComponent = null;

  private dialogModule: Module = null;

  private dialogOptions: GenericDialogOptions = {
    width: 800,
    height: 600,
    isModal: true
  };

  private subscriptions: Subscription[] = [];

  constructor(
    protected genericCrudService: GenericCrudService,
    protected locationService: LocationService,
    protected apiBuilderService: ApiBuilderService,
    protected modulesStateService: ModulesStateService,
    protected entityDataStore: EntityDataStoreService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected elementStateService: ElementsStateService,
    protected elementsStackService: ElementsStackService,
    protected dataSharingService: DataSharingService,
    protected dialogService: GenericDialogModuleService
  ) { }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /**
   * @param field
   * @description return field or moduleElement
   */
  private getDoubleClickElement(field: FieldMetadataGrid | null): DoubleClickAware {
    return this.getComponent().moduleElement;
  }

  public openModuleInNewTab(targetModule: Module, entity: any, parentModuleElement: ModuleElement, component?: AbstractGenericGridComponent|FormViewerComponent,
                            part?: number) {
    this.locationService.getRouter().navigate(
      [ModuleRouter.buildRoute(targetModule, parentModuleElement.moduleId, entity, part, this.getProductPath(this.locationService.getRouter()), this.getProductId(this.locationService.getRouter()))],
      {
        state: {
          isNewTab: true
        }
      }
    ).then();
  }

  public openModuleInNewTabCustom(targetModule: Module, route: string, fqn: string,
                                  parentModuleElement: ModuleElement, id: number) {

    const entity = {
      fqn: fqn,
      id: id
    };

    this.locationService.getRouter()
      .navigate([
        ModuleRouter.buildRoute(targetModule, parentModuleElement.moduleId, entity, null, this.getProductPath(this.locationService.getRouter()), this.getProductId(this.locationService.getRouter()))
      ]).then();
  }

  public openModuleInCurrentTab(targetModule: Module, entity: any, parentModuleElement: ModuleElement, part?: number) {
    this.modulesStateService.hide(
      this.modulesStateService.getById(parentModuleElement.moduleId)
    );

    this.locationService.getRouter().navigate([
      ModuleRouter.buildRoute(targetModule, parentModuleElement.moduleId, entity, part, this.getProductPath(this.locationService.getRouter()), this.getProductId(this.locationService.getRouter()))
    ]).then();
  }

  public openModuleInDialog(targetModule: Module, entity: any, parentModuleElement: ModuleElement, genericComponent: GenericElementAbstract, masterEntity?: any, masterEntityEditingField?: string) {
    const masterEntityName = (entity.fqn) ? this.constructMasterEntityName(entity.fqn.split('\\').pop()) : null;

    // Dialog:

    const height = this.dialogOptions.height,
      width = this.dialogOptions.width,
      isModal = this.dialogOptions.isModal;

    this.dialogService.showDialog(targetModule, {
      height: height,
      width: width,
      maximized: this.dialogOptions.maximized,
      entity: entity,
      masterEntity: masterEntity,
      masterEntityEditingField: masterEntityEditingField,
      masterField: masterEntityName,
      masterElementContext: genericComponent.getElementContext(),
      isModal: isModal,
      onClose: () => {
        // todo :: check drafts, should be closed immediately

        const moduleState = this.modulesStateService.getById(targetModule.id);

        if (moduleState.isDialog && genericComponent instanceof AbstractGenericGridComponent) {
          const components = moduleState.getAllComponents();

          genericComponent.getCancelChangesService()
            .setExecutorService(genericComponent.getExecutor())
            .cancel(components);

          return false;
        }

        return true;
      }
    });
  }

  public handleOpenModule(component: AbstractGenericGridComponent|FormViewerComponent|GenericFullCalendarComponent, onDoubleClickTarget: string,
                          onDoubleClickTargetModuleId: number, entity: any, masterEntity?: any, masterEntityEditingField?: string) {
    this.dialogModule = null;

    if (!onDoubleClickTargetModuleId) {
      throw new Error('Double click target module id is not assigned');
    }

    this.genericCrudService.getEntities(`superadmin/modules/${onDoubleClickTargetModuleId}`).subscribe((targetModule) => {
      const currentMainModuleState = this.modulesStateService.getByComponent(component),
        isDialog = onDoubleClickTarget === ModuleElement.DOUBLE_CLICK_TARGET_DIALOG,
        childModuleState = new ModuleState(targetModule.id, targetModule, '', {}, !isDialog, entity, false, [], isDialog, false, false, currentMainModuleState);

      const isDisabledActive = component.getElementContext().getRuntimeFlag(RuntimeFlagName.DisableDialogPersistHideOnNewDialog);

      if (currentMainModuleState && currentMainModuleState.isDialog && (!isDisabledActive || isDisabledActive.active !== true)) {
        this.dialogService.persistHide();
      }

      this.modulesStateService.add(childModuleState);

      if (onDoubleClickTarget === ModuleElement.DOUBLE_CLICK_TARGET_NEW_TAB) {
        this.openModuleInNewTab(targetModule, entity, component.moduleElement);
      } else if (onDoubleClickTarget === ModuleElement.DOUBLE_CLICK_TARGET_CURRENT_TAB) {
        this.openModuleInCurrentTab(targetModule, entity, component.moduleElement);
      } else {
        this.openModuleInDialog(targetModule, entity, component.moduleElement, component, masterEntity, masterEntityEditingField);
      }

    });
  }

  public handleOpenElement(component: AbstractGenericGridComponent | GenericFullCalendarComponent, onDoubleClickTargetElementId: number) {

    this.genericCrudService.getEntities(`superadmin/elements/${onDoubleClickTargetElementId}`).subscribe((targetElement) => {
      component.moduleElementTargetElement = targetElement;
    });
  }

  public onDoubleClick(event: any) {
    const component = this.getComponent();
    const doubleClickElement = component.moduleElement;

    const onDoubleClick = doubleClickElement.onDoubleClick,
      onDoubleClickTarget = doubleClickElement.onDoubleClickTarget,
      onDoubleClickTargetDialogWidth = +doubleClickElement.onDoubleClickTargetDialogWidth,
      onDoubleClickTargetDialogHeight = +doubleClickElement.onDoubleClickTargetDialogHeight,
      onDoubleClickTargetElementId = doubleClickElement.onDoubleClickTargetElementId,
      onDoubleClickTargetModuleId = doubleClickElement.onDoubleClickTargetModuleId;

    this.setDialogOptions({
      height: onDoubleClickTargetDialogHeight,
      width: onDoubleClickTargetDialogWidth
    });

    switch (onDoubleClick) {
      case ModuleElement.DOUBLE_CLICK_DETAILS:
        component.showDetailedOverview = true;
        break;
      case ModuleElement.DOUBLE_CLICK_ELEMENT:
        this.handleOpenElement(component, onDoubleClickTargetElementId);
        break;
      case ModuleElement.DOUBLE_CLICK_MODULE:
        this.handleOpenModule(component, onDoubleClickTarget, onDoubleClickTargetModuleId, component.selectedEntity);
        break;
    }
  }

  public setComponent(component: AbstractGenericGridComponent | GenericFullCalendarComponent): this {
    this.component = component;
    return this;
  }


  public getComponent(): AbstractGenericGridComponent | GenericFullCalendarComponent {
    return this.component;
  }

  public setForm(form: FormViewerComponent): this {
    this.form = form;

    return this;
  }

  public getForm(): FormViewerComponent {
    return this.form;
  }

  public setDialogOptions(options: GenericDialogOptions): this {
    this.dialogOptions.width = options.width || 800;
    this.dialogOptions.height = options.height || 600;
    this.dialogOptions.isModal = options.isModal || false;
    this.dialogOptions.maximized = options.maximized || false;

    return this;
  }

  private constructMasterEntityName(entityName: string): string {
    return entityName.charAt(0).toLowerCase() + entityName.slice(1);
  }

  private getProductPath(router: Router): string|null {
    const urlSplit = router.url.split('/').filter((val) => (val !== ''));
    if (urlSplit.length >= 2) {
      return urlSplit.shift();
    }

    return null;
  }

  private getProductId(router: Router): number|null {
    const urlSplit = router.url.split('/').filter((val) => (val !== ''));
    if (urlSplit.length >= 2) {
      // tslint:disable-next-line:radix
      return parseInt(urlSplit[1]);
    }

    return null;
  }
}
