import {of as observableOf, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import {ElementLayoutGlobalFields} from '../models';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {ElementInputComponent} from './element-input.component';
import {ComponentSelectedOptionAware} from './abstract-element.component';
import {TranslateService} from '@ngx-translate/core';
import {DatamodelCrudService} from '../../services/datamodel/datamodel.crud.service';
import {ElementService} from '../../../form-editor/shared/services';
import {ElementsStackService} from '../../content-renderer/services/elements-stack.service';
import {GenericCrudService} from '../../services/generic-crud.service';
import {FormActionsService} from '../actions/services/form-actions.service';
import {EntityDraftStoreService} from '../../content-renderer/services/entity-draft-store.service';
import {Constants} from '../../../constants';
import {ReferenceAssociationFactorParameters} from '../models/element-layout-global-fields';
import {SelectItemExtended} from './element-input-autocomplete.component';
import {EntityManagerService} from '../../../core/service/entity-manager/entity-manager.service';
import {Entity} from '../../helpers/entity';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-layout-global-fields',
  templateUrl: './element-global-fields.component.html',
  styles: [`
    :host > div {
      padding: .5em 0;
    }

    :host > div > p-fieldset {
      width: 100%;
    }

    #inputField {
      width: 60%;
    }

    #inputLabel {
      width: 40%;
    }

    p-dropdown,
    p-dropdown ::ng-deep .ui-dropdown {
      width: 100%;
    }

    :host > div > ::ng-deep p-fieldset fieldset {
      border-radius: 15px;
    }

    p-checkbox ::ng-deep .ui-chkbox {
      padding-top: .48em;
      padding-bottom: .48em;
    }
  `]
})

export class ElementLayoutGlobalFieldsComponent
  extends ElementInputComponent
  implements OnInit, OnDestroy, AfterViewInit, ComponentSelectedOptionAware {

  @Input() element: ElementLayoutGlobalFields;

  public elements = [];
  public globalFields = [];
  public wageAmountLoading = false;
  public wageAmountField = 'wageAmount';
  public referenceAssociationFactorField = 'referenceAssociationFactor';
  public collectiveAgreementId = null;
  public salaryClassificationId = null;
  public salaryUnitId = null;

  public referenceAssociationParameters = new ReferenceAssociationFactorParameters;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected genericCrudService: GenericCrudService,
    protected elementService: ElementService,
    protected elementRef: ElementRef,
    protected datamodelCrudService: DatamodelCrudService,
    protected translateService: TranslateService,
    protected elementsStackService: ElementsStackService,
    protected formActionsService: FormActionsService,
    protected entityDraftService: EntityDraftStoreService,
    protected entityManager: EntityManagerService
  ) {
    super(formService, cdr, formViewerService, translateService, datamodelCrudService);
  }

  onComponentInit() {
    if (this.element.datamodelField === 'secondaryGlobalFields') {
      this.wageAmountField = 'secondaryWageAmount';
      this.referenceAssociationFactorField = 'secondaryReferenceAssociationFactor';
    }

    this.collectiveAgreementId = null;
    this.salaryClassificationId = null;
    this.salaryUnitId = null;

    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');
    this.subscriptions.push(
      this.formService.formElementAfterValueChanged$.subscribe((element) => {
        this.loadReferenceAssociationFactor(element.entity).subscribe((res) => {
          this.loadElements(element.entity);
        });
      }),
      this.formService.formEntityChanged$.subscribe((entity) => {
        this.checkAndApplyNewValuesOfReferenceAssociationProperties(entity).subscribe((res) => {
            this.loadElements(entity, false);
          }
        );
      })
    );
    this.checkAndApplyNewValuesOfReferenceAssociationProperties(this.element.entity).subscribe((res) => {
        if (this.element.entity) {
          this.loadElements(this.element.entity, !this.element.entity.id);
        }
      }
    );
  }

  loadElements(selectedEntity, triggerCalculation: boolean = true) {
    const newCollectiveAgreementId = this.getCollectiveAgreementId(selectedEntity),
      newSalaryClassificationId = this.getFieldId(selectedEntity, this.element.salaryClassificationField),
      newSalaryUnitId = this.getFieldId(selectedEntity, 'salaryUnit');

    if (newCollectiveAgreementId && newSalaryClassificationId) {
      if (newCollectiveAgreementId !== this.collectiveAgreementId || newSalaryClassificationId !== this.salaryClassificationId) {
        this.formService.getGenericCrudService().get('phoenix/collectiveagreements/' + newCollectiveAgreementId + '/salaryclassification/' + newSalaryClassificationId + '/globalfields').subscribe((elements) => {
          let globalFields = [];
          this.globalFields = [];
          if (typeof this.entity[this.element.datamodelField] === 'undefined') {
            this.setEntityValue(this.element.datamodelField, []);
          }
          if (elements && elements[this.element.globalFieldSection]) {
            elements = elements[this.element.globalFieldSection];
            for (let element of elements) {
              let globalField = this.getGlobalField(element.name);
              globalFields.push(this.setUpGlobalField(selectedEntity, element, globalField));
            }
            this.setEntityValue(this.element.datamodelField, globalFields);
            this.elements = elements;
          } else {
            this.elements = [];
          }
          if (triggerCalculation) {
            this.getWageAmount(selectedEntity);
          }

          this.markElementForCheck(this.element);
          ChangeDetectorRefHelper.detectChanges(this);
        });
      } else if (newSalaryUnitId !== this.salaryUnitId && this.entity.hasOwnProperty(this.element.datamodelField)) {
        this.getWageAmount(selectedEntity);
      }
    } else {
      this.elements = [];
      if (this.entity) {
        this.setEntityValue(this.wageAmountField, null);
      }
    }
    this.collectiveAgreementId = newCollectiveAgreementId;
    this.salaryClassificationId = newSalaryClassificationId;
    this.salaryUnitId = newSalaryUnitId;
  }

  public addOption(option: SelectItemExtended): this {

    return this;
  }

  getWageAmount(selectedEntity) {
    let data = {
      conditions: {},
      salaryUnitId: this.getFieldId(selectedEntity, 'salaryUnit'),
      wageType: this.element.globalFieldSection
    };

    if (this.entity[this.element.datamodelField] && this.entity[this.element.datamodelField] instanceof Array && data.salaryUnitId) {
      for (let globalField of this.entity[this.element.datamodelField]) {
        if (typeof globalField.value == 'undefined') {
          this.setEntityValue(this.wageAmountField, null);
          return false;
        } else {
          data.conditions[globalField.name] = globalField.value;
        }
      }

      this.wageAmountLoading = true;
      this.formService.getGenericCrudService().customPost('phoenix/collectiveagreements/' + this.getCollectiveAgreementId(selectedEntity) + '/wages', data).subscribe((elements) => {
        if (elements && elements[this.element.globalFieldSection]) {
          this.setEntityValue(this.wageAmountField, elements[this.element.globalFieldSection].amount);
        } else {
          this.setEntityValue(this.wageAmountField, null);
        }
        this.wageAmountLoading = false;

        this.markElementForCheck(this.element);
      });
    } else {
      this.setEntityValue(this.wageAmountField, null);

      this.markElementForCheck(this.element);
    }

  }

  getGlobalField(fieldName) {
    for (let globalField of this.entity[this.element.datamodelField]) {
      if (globalField.name === fieldName) {
        return globalField;
      }
    }
    return false;
  }

  getCollectiveAgreementId(selectedEntity) {
    let collectiveAgreement = null,
      field = this.element.collectiveAgreementField;

    if (selectedEntity && field) {
      collectiveAgreement = this.formService.getEntityHydrator().getEntityPropertyValue(selectedEntity, field, false, true)
    }

    if (this.element.isCollectiveAgreementFromLocalStorage) {
      if (collectiveAgreement && collectiveAgreement.isForEmployees) {
        collectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_EMPLOYEES);
      } else if (collectiveAgreement && collectiveAgreement.isForWorkpersons) {
        collectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER);
      } else {
        collectiveAgreement = null;
      }
    }

    if (collectiveAgreement && collectiveAgreement.fqn == 'PhoenixBundle\\Entity\\CollectiveAgreement') {
      return collectiveAgreement.id;
    } else if (typeof collectiveAgreement === 'number') {
      return collectiveAgreement;
    } else {
      return false;
    }
  }

  getFieldId(selectedEntity, field) {
    let entity = null;

    if (selectedEntity && field) {
      entity = this.formService.getEntityHydrator().getEntityPropertyValue(selectedEntity, field, false, true)
    }

    if (entity && entity.id) {
      return entity.id;
    } else if (typeof entity == 'number') {
      return entity;
    } else {
      return null;
    }
  }

  getField(selectedEntity, field) {
    let entity = null;

    if (selectedEntity && field) {
      entity = this.formService.getEntityHydrator().getEntityPropertyValue(selectedEntity, field, false, true);
    }

    return entity;
  }

  loadReferenceAssociationFactor(selectedEntity) {
    return this.checkAndApplyNewValuesOfReferenceAssociationProperties(selectedEntity).pipe(map(res => {
      if (res) {
        if (this.referenceAssociationParameters.referenceAssociation
          && this.referenceAssociationParameters.referenceAssociationType
          && this.isProperReferenceAssociationType(selectedEntity)) {

          this.formService.getGenericCrudService().customPost('phoenix/referenceassociations/2/referencefactory', this.referenceAssociationParameters).subscribe((elements) => {
            if (elements && elements['referenceAssociationFactor']) {
              this.setEntityValue(this.referenceAssociationFactorField, elements['referenceAssociationFactor']);
            } else {
              this.setEntityValue(this.referenceAssociationFactorField, null);
            }

            this.markElementForCheck(this.element);
          });
        } else {
          this.setEntityValue(this.referenceAssociationFactorField, null);

          this.markElementForCheck(this.element);
        }
      }
      return true;
    }));
  }

  isProperReferenceAssociationType(selectedEntity): boolean {
    let workingMode = selectedEntity.workingMode ? selectedEntity.workingMode : null;

    if (selectedEntity._embedded && selectedEntity._embedded.workingMode) {
      workingMode = selectedEntity._embedded.workingMode;
    }

    if (this.referenceAssociationParameters.referenceAssociationType
      && this.referenceAssociationParameters.referenceAssociationType === 2 && workingMode && workingMode.code === 'MONTAGE'
    ) {
      return false;
    }

    return true;
  }

  checkAndApplyNewValuesOfReferenceAssociationProperties(selectedEntity): Observable<boolean> {
    return this.getCollectiveAgreement(selectedEntity).pipe(map(res => {
      if (res) {
        selectedEntity['collectiveAgreement'] = res;
        selectedEntity['_embedded']['collectiveAgreement'] = res;
        const hasCompanyAgreement = this.getField(selectedEntity, this.element.fieldHasCompanyAgreement),
          workingMode = this.getFieldId(selectedEntity, this.element.fieldWorkingMode),
          referenceAssociation = this.getFieldId(selectedEntity, this.element.fieldReferenceAssociation),
          referenceAssociationType = this.getFieldId(selectedEntity, this.element.fieldReferenceAssociationType);

        let hasChanged = false;

        if (hasCompanyAgreement !== this.referenceAssociationParameters.hasCompanyAgreement) {
          this.referenceAssociationParameters.hasCompanyAgreement = hasCompanyAgreement;
          hasChanged = true;
        }

        if (workingMode !== this.referenceAssociationParameters.workingMode) {
          this.referenceAssociationParameters.workingMode = workingMode;
          hasChanged = true;
        }

        if (referenceAssociation !== this.referenceAssociationParameters.referenceAssociation) {
          this.referenceAssociationParameters.referenceAssociation = referenceAssociation;
          hasChanged = true;
        }

        if (referenceAssociationType !== this.referenceAssociationParameters.referenceAssociationType) {
          this.referenceAssociationParameters.referenceAssociationType = referenceAssociationType;
          hasChanged = true;
        }

        return hasChanged;
      }
    }));
  }

  getCollectiveAgreement(selectedEntity): Observable<any> {
    let collectivAgreementId = Entity.getValue(selectedEntity, this.element.collectiveAgreementField);
    if (collectivAgreementId && collectivAgreementId.fqn === 'PhoenixBundle\\Entity\\CollectiveAgreement') {
      collectivAgreementId = collectivAgreementId.id;
    }
    return collectivAgreementId ?
      this.genericCrudService.getEntity(
        'phoenix/collectiveagreements',
        collectivAgreementId,
        '',
        {
          embedded: 'referenceAssociationType'
        }) : observableOf(null);
  }

  getFactorisedWage() {
    if (typeof this.entity[this.wageAmountField] == 'number' && typeof this.entity[this.referenceAssociationFactorField] == 'number') {
      let factorizedWage = this.entity[this.wageAmountField] * (1 + this.entity[this.referenceAssociationFactorField]);
      return factorizedWage.toFixed(2);
    } else {
      return null;
    }
  }

  onComponentDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit() {
  }

  onChange(fieldName, event) {
    let globalField = this.getGlobalField(fieldName);
    globalField.value = this.globalFields[fieldName];
    globalField.isChanged = true;
    this.setValue(this.entity[this.element.datamodelField]);
    this.getWageAmount(this.entity);
  }

  onDateChange(fieldName, event) {
    let globalField = this.getGlobalField(fieldName);
    globalField.value = event;
    this.globalFields[fieldName] = event;
    globalField.isChanged = true;
    this.setValue(this.entity[this.element.datamodelField]);
    this.getWageAmount(this.entity);
  }

  onPeriodChange(fieldName, event) {
    this.globalFields[fieldName] = event;
    let globalField = this.getGlobalField(fieldName);
    globalField.value = this.globalFields[fieldName];
    globalField.isChanged = true;
    this.setValue(this.entity[this.element.datamodelField]);
    this.getWageAmount(this.entity);
  }

  isGlobalFieldHidden(selectedElement) {
    return selectedElement.name === 'Beschäftigungsgruppe' || selectedElement.isHidden;
  }

  isGlobalFieldReadOnly(selectedElement) {
    if (selectedElement.name === 'Berufsjahr' && this.referenceAssociationParameters.referenceAssociationType && this.referenceAssociationParameters.referenceAssociationType !== 1) {
      return true;
    }
    return false;
  }

  setUpGlobalField(selectedEntity, element, globalField) {
    if (!globalField) {
      if (element.typeElement === 'enum' && element.allowedValues && element.allowedValues[0] && typeof element.allowedValues[0].value !== 'undefined') {
        globalField = {'name': element.name, 'value': element.allowedValues[0].value};
      } else {
        globalField = {'name': element.name, 'value': null};
      }
    }

    if (element.typeElement === 'boolean') {
      globalField.value = !!globalField.value;
    }

    if (element.name == 'Beschäftigungsgruppe') {
      let salaryClassification = this.getField(selectedEntity, this.element.salaryClassificationField);
      if (salaryClassification && salaryClassification.name) {
        globalField.value = salaryClassification.name;
      }
    }

    if (element.name == 'Berufsjahr') {
      if (this.referenceAssociationParameters.referenceAssociationType) {
        globalField.value = 0;
      }
    }

    this.globalFields[element.name] = globalField.value;

    return globalField;
  }

  private setEntityValue(field, value) {
    const oldValue = this.entity[field];
    this.entity[field] = value;
    this.entityManager.persist(this.entity, {property: field, oldValue: oldValue, newValue: value, force: true});
  }
}

