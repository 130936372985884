import {Component, ElementRef} from '@angular/core';
import {AbstractGridColumnComponent} from '../../../abstract-grid-column.component';

@Component({
  selector: 'app-comparator-column',
  template: `
    <p-dropdown
        *ngIf="isEditMode()"
        [(ngModel)]="entity.comparator"
        [options]="comparatorsOptions"
        placeholder="---"
        (onChange)="onComparatorChange()"
        [style]="{'width': '100%'}"
        appendTo="body"
    ></p-dropdown>
    
    <div *ngIf="!isEditMode()">
      {{ entity.comparator }}
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `]
})
export class ConditionTreeComparatorColumnComponent extends AbstractGridColumnComponent {

  public comparatorsOptions: SelectItem[] = [
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: '=', label: '=' },
    { value: '>=', label: '>=' },
    { value: '>', label: '>' }
  ];

  public constructor(
    protected elementRef: ElementRef
  ) {
    super(elementRef);
  }

  public onComparatorChange(): void {
    this.component.flagEntityForBulkSave(this.entity).subscribe(() => {
      this.component.changeEntityValue(this.entity, this.column, this.entity['comparator'], '');
    });
  }
}
