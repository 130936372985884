/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../../../../p-button/p-button.directive";
import * as i4 from "./inquiry-position-action-column.component";
import * as i5 from "../../../../services/double-click.service";
import * as i6 from "../../../../../services/generic-crud.service";
import * as i7 from "../../../../../../core/job-runner/job-container.service";
import * as i8 from "../../../generic-dialog/service/generic-dialog-module.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../../services/modules-state.service";
import * as i11 from "../../../../../../core/executor/builder/execution-step-builder.service";
import * as i12 from "../../../../../../core/executor/executor.service";
import * as i13 from "../../../../../../core/message/message.service";
var styles_InquiryPositionActionColumnComponent = [];
var RenderType_InquiryPositionActionColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InquiryPositionActionColumnComponent, data: {} });
export { RenderType_InquiryPositionActionColumnComponent as RenderType_InquiryPositionActionColumnComponent };
export function View_InquiryPositionActionColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "table", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "td", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["pButton", ""]], [[4, "width", null], [4, "background-color", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openOffer($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(5, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵdid(6, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "td", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["pButton", ""]], [[4, "width", null], [4, "background-color", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openOrder($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(10, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵdid(11, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 4, "td", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["pButton", ""]], [[4, "width", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAssignments($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(15, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵdid(16, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.getButtonLabel("offer"), ""); _ck(_v, 5, 0, currVal_4); var currVal_5 = i0.ɵinlineInterpolate(1, "", _co.getButtonLabel("offer"), ""); _ck(_v, 6, 0, currVal_5); var currVal_10 = i0.ɵinlineInterpolate(1, "", _co.getButtonLabel("order"), ""); _ck(_v, 10, 0, currVal_10); var currVal_11 = i0.ɵinlineInterpolate(1, "", _co.getButtonLabel("order"), ""); _ck(_v, 11, 0, currVal_11); var currVal_15 = i0.ɵinlineInterpolate(1, "", _co.getButtonLabel("assignment"), ""); _ck(_v, 15, 0, currVal_15); var currVal_16 = i0.ɵinlineInterpolate(1, "", _co.getButtonLabel("assignment"), ""); _ck(_v, 16, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isHidden("offer"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getButtonWidth(); var currVal_2 = _co.getOfferButtonStyleClass(); var currVal_3 = _co.isDisabled("offer"); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.isHidden("order"); _ck(_v, 7, 0, currVal_6); var currVal_7 = _co.getButtonWidth(); var currVal_8 = _co.getOrderButtonStyleClass(); var currVal_9 = _co.isDisabled("order"); _ck(_v, 8, 0, currVal_7, currVal_8, currVal_9); var currVal_12 = _co.isHidden("assignment"); _ck(_v, 12, 0, currVal_12); var currVal_13 = _co.getButtonWidth(); var currVal_14 = _co.isDisabled("assignment"); _ck(_v, 13, 0, currVal_13, currVal_14); }); }
export function View_InquiryPositionActionColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-inquiry-position-column", [], null, null, null, View_InquiryPositionActionColumnComponent_0, RenderType_InquiryPositionActionColumnComponent)), i0.ɵdid(1, 114688, null, 0, i4.InquiryPositionActionColumnComponent, [i0.ElementRef, i5.DoubleClickService, i6.GenericCrudService, i7.JobContainerService, i8.GenericDialogModuleService, i9.TranslateService, i10.ModulesStateService, i11.ExecutionStepBuilderService, i12.ExecutorService, i13.MessageService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InquiryPositionActionColumnComponentNgFactory = i0.ɵccf("app-inquiry-position-column", i4.InquiryPositionActionColumnComponent, View_InquiryPositionActionColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { InquiryPositionActionColumnComponentNgFactory as InquiryPositionActionColumnComponentNgFactory };
