<table>
  <tr>
    <td [hidden]="isHidden('offer')">
      <button pButton
              [style.width]="getButtonWidth()"
              [style.background-color]="getOfferButtonStyleClass()"
              [disabled]="isDisabled('offer')"
              label="{{getButtonLabel('offer')}}"
              (click)="openOffer($event)">
      </button>
    </td>
    <td [hidden]="isHidden('order')">
      <button pButton
              [style.width]="getButtonWidth()"
              [style.background-color]="getOrderButtonStyleClass()"
              [disabled]="isDisabled('order')"
              label="{{getButtonLabel('order')}}"
              (click)="openOrder($event)">

      </button>
    </td>
    <td [hidden]="isHidden('assignment')">
      <button pButton
              [style.width]="getButtonWidth()"
              [disabled]="isDisabled('assignment')"
              label="{{getButtonLabel('assignment')}}"
              (click)="openAssignments($event)">
      </button>
    </td>
  </tr>
</table>
