<div class="ui-g filter-component" #filterContainer>
    <div class="filter-item-component" [style.width]="filterItem.width" *ngFor="let filterItem of filterItems">

        <div>
            {{filterItem.label}}&nbsp;
        </div>

        <div [ngSwitch]="filterItem.filterType">
            <ng-template ngSwitchCase="contains">
                <div class="ui-inputgroup">
                    <input
                        [componentState]="component.componentState"
                        (search)="onFilter($event, filterItem)"
                        (keyup)="onFilter($event, filterItem)"
                        gridFilter
                        [column]="{property:filterItem.fieldName}"
                        [ngModel]=""
                        type="search"
                        pInputText
                        styleClass="ui-column-filter"
                        placeholder="Contains"
                        class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                    />
                </div>
            </ng-template>
            <ng-template ngSwitchCase="equals">
                <div class="ui-inputgroup">
                    <input
                        [componentState]="component.componentState"
                        (search)="onFilter($event, filterItem)"
                        (keyup)="onFilter($event, filterItem)"
                        gridFilter
                        [column]="{property:filterItem.fieldName}"
                        [ngModel]=""
                        type="search"
                        pInputText
                        styleClass="ui-column-filter"
                        placeholder="Equals"
                        class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                    />
                </div>
            </ng-template>
            <ng-template ngSwitchCase="startsWith">
                <div class="ui-inputgroup">
                    <input
                        [componentState]="component.componentState"
                        (search)="onFilter($event, filterItem)"
                        (keyup)="onFilter($event, filterItem)"
                        gridFilter
                        [column]="{property:filterItem.fieldName}"
                        [ngModel]=""
                        type="search"
                        pInputText
                        styleClass="ui-column-filter"
                        placeholder="Starts with"
                        class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                    />
                </div>
            </ng-template>
            <ng-template ngSwitchCase="endsWith">
                <div class="ui-inputgroup">
                    <input
                        [componentState]="component.componentState"
                        (search)="onFilter($event, filterItem)"
                        (keyup)="onFilter($event, filterItem)"
                        gridFilter
                        [column]="{property:filterItem.fieldName}"
                        [ngModel]=""
                        type="search"
                        pInputText
                        styleClass="ui-column-filter"
                        placeholder="Ends with"
                        class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                    />
                </div>
            </ng-template>
            <ng-template ngSwitchCase="in">
                <div class="ui-inputgroup">
                    <input
                        [componentState]="component.componentState"
                        (search)="onFilter($event, filterItem)"
                        (keyup)="onFilter($event, filterItem)"
                        gridFilter
                        [column]="{property:filterItem.fieldName}"
                        [ngModel]=""
                        type="search"
                        pInputText
                        styleClass="ui-column-filter"
                        placeholder="In"
                        class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                    />
                </div>
            </ng-template>
            <ng-template ngSwitchCase="date">
                <input
                    [componentState]="component.componentState"
                    (search)="onFilter($event, filterItem)"
                    (keyup)="onFilter($event, filterItem)"
                    gridFilter
                    [column]="{property:filterItem.fieldName}"
                    [ngModel]=""
                    type="search"
                    pInputText
                    styleClass="ui-column-filter"
                    placeholder="Date"
                    class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                />
            </ng-template>
            <ng-template ngSwitchCase="range_date">
                <p-slider
                    [componentState]="component.componentState"
                    [range]="true"
                    [min]="dateRangeMinValue"
                    [max]="dateRangeMaxValue"
                    [ngModel]="dateRangeValues"
                    (onSlideEnd)="onFilter($event, filterItem)"
                    gridFilter
                    [column]="{property:filterItem.fieldName}"
                    styleClass="ui-column-filter"
                ></p-slider>
                <span>{{dateRangeValues[0]}} - {{dateRangeValues[1]}}</span>
            </ng-template>
            <ng-template ngSwitchCase="checkbox">
                <p-checkbox
                    (onChange)="onFilter($event, filterItem)"
                    [ngModel]=""
                    binary="true"
                ></p-checkbox>
            </ng-template>
            <ng-template ngSwitchCase="tricheckbox">
                <p-triStateCheckbox
                    (onChange)="onFilter($event, filterItem)"
                    [ngModel]=""
                ></p-triStateCheckbox>
            </ng-template>
            <ng-template ngSwitchCase="dropdown">
                <p-dropdown
                    gridFilter
                    [column]="{property:filterItem.fieldName}"
                    [componentState]="component.componentState"
                    [options]="component.associatedFilterOptions[filterItem.fieldName.indexOf('.') != -1 ? filterItem.fieldName.split('.')[0] : filterItem.fieldName]"
                    [style]="{'width':'100%'}"
                    [ngModel]=""
                    (onChange)="onFilter($event, filterItem)"
                    appendTo="body"
                    styleClass="ui-column-filter"
                ></p-dropdown>
            </ng-template>
            <ng-template ngSwitchCase="multidropdown">
                <p-multiSelect
                    [componentState]="component.componentState"
                    [ngModel]=""
                    [options]="component.associatedFilterOptions[filterItem.fieldName.indexOf('.') != -1 ? filterItem.fieldName.split('.')[0] : filterItem.fieldName]"
                    (onChange)="onFilter($event, filterItem)"
                    appendTo="body"
                    gridFilter
                    [column]="{property:filterItem.fieldName}"
                    selectedItemsLabel="Alle ({0})"
                    maxSelectedLabels="2"
                    defaultLabel="All"
                    styleClass="ui-column-filter"
                ></p-multiSelect>
            </ng-template>
            <ng-template ngSwitchCase="autocomplete">
                <p-autoComplete
                    [componentState]="component.componentState"
                    [dropdown]="true"
                    [ngModel]=""
                    [suggestions]="component.associatedFilterOptions[filterItem.fieldName.indexOf('.') != -1 ? filterItem.fieldName.split('.')[0] : filterItem.fieldName]"
                    [multiple]="true"
                    [style]="{'width':'100%', 'white-space': 'nowrap'}"
                    (onSelect)="onFilter($event, filterItem)"
                    (onUnselect)="onFilter($event, filterItem)"
                    (completeMethod)="onFilterAutocompleteSearch($event, filterItem)"
                    (onDropdownClick)="onFilterAutocompleteSearch($event, filterItem)"
                    appendTo="body"
                    field="label"
                    gridFilter
                    [column]="{property:filterItem.fieldName}"
                    styleClass="ui-column-filter ui-column-filter-autocomplete"
                ></p-autoComplete>
            </ng-template>
            <ng-template ngSwitchCase="months">
              <p-dropdown
                gridFilter
                [column]="{property:filterItem.fieldName}"
                [componentState]="component.componentState"
                [options]="selectableMonths"
                [style]="{'width':'100%'}"
                [ngModel]=""
                (onChange)="onFilter($event, filterItem)"
                appendTo="body"
                styleClass="ui-column-filter"
              ></p-dropdown>
            </ng-template>
            <ng-template ngSwitchCase="custom">
              <app-custom-filter-class #customGridFilter [filterItem]="filterItem" [component]="component" [genericFilterComponent]="this"></app-custom-filter-class>
            </ng-template>
            <ng-template ngSwitchDefault="contains">
                <div class="ui-inputgroup">
                    <input
                        [componentState]="component.componentState"
                        (search)="onFilter($event, filterItem)"
                        (keyup)="onFilter($event, filterItem)"
                        gridFilter
                        [column]="{property:filterItem.fieldName}"
                        [ngModel]=""
                        type="search"
                        pInputText
                        styleClass="ui-column-filter"
                        placeholder="Contains"
                        class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                    />
                </div>
            </ng-template>
        </div>
    </div>
    <div class="ui-g-6 ui-md-3 ui-lg-2">
      <div class="filter-component-buttons">
        <button *ngIf="!component.element.isFilterContainerAlwaysVisible" type="button" pButton label="{{ 'COMMON.CLOSE' | translate }}" icon="fa fa-fw fa-close" (click)="onCancel()"></button>
        <button type="button" pButton label="{{ 'COMMON.CLEAR' | translate }}" icon="fa fa-fw fa-trash" (click)="onClear()"></button>
        <button type="button" pButton label="{{ 'COMMON.SEARCH' | translate }}" icon="fa fa-fw fa-search" (click)="onSearch()"></button>
      </div>
    </div>
</div>
