<split>
  <split-area [size]="100">
    <div>
      <p-fieldset legend="{{ 'COMMON.SEARCH' | translate }}">
        <div class="ui-grid-row">
          <div class="ui-grid-col-6">
            {{ 'COMMON.NAME' | translate }} *:
          </div>
          <div class="ui-grid-col-6">
            <input type="text" id="CompanySearch-Name" pInputText placeholder="Minimum 3 Buchstaben..." [(ngModel)]="companyName"/>
          </div>
        </div>

        <div class="ui-grid-row">
          <div class="ui-grid-col-6">
            City:
          </div>
          <div class="ui-grid-col-6">
            <input type="text" id="CompanySearch-City" pInputText placeholder="Minimum 3 Buchstaben" [(ngModel)]="companyCity">
          </div>
        </div>

        <div class="ui-grid-row">
          <div class="ui-grid-col-6">
            Bundesland:
          </div>
          <div class="ui-grid-col-6">
            <p-dropdown id="CompanySearch-Region" [options]="regions" placeholder="---" filter="true" [(ngModel)]="region">
              <ng-template let-item pTemplate="item">
                <span
                  [id]="'CompanySearch-Region-Value-' + item.label"
                >
                  {{ item.label }}
                </span>
              </ng-template>
            </p-dropdown>
          </div>
        </div>

        <div class="ui-grid-row">
          <div class="ui-grid-col-6">
            UID:
          </div>
          <div class="ui-grid-col-6">
            <input type="text" id="CompanySearch-UID" pInputText placeholder="UID Nummer" [(ngModel)]="companyUid">
          </div>
        </div>

        <div class="ui-grid-row">
          <div class="ui-grid-col-6">
            {{ 'COMMON.COMPANY_REGISTER' | translate }}:
          </div>
          <div class="ui-grid-col-6">
            <p-checkbox name="groupname" id="CompanySearch-Register" value="companyRegister" [(ngModel)]="companySearchCheckboxes"></p-checkbox>
          </div>
        </div>

        <div class="ui-grid-row">
          <div class="ui-grid-col-6">
            {{ 'COMMON.COMMERCIAL_REGISTER' | translate }}:
          </div>
          <div class="ui-grid-col-6">
            <p-checkbox name="groupname" id="CompanySearch-Commercial" value="commercialRegister" [(ngModel)]="companySearchCheckboxes"></p-checkbox>
          </div>
        </div>
        <p-footer>
          <div class="ui-grid-row">
            <button pButton type="button" id="CompanySearch-Button" [disabled]="!companyName || companyName.length < 3" (click)="onSearch()" label="{{ 'COMMON.SEARCH' | translate }}"></button>
          </div>
        </p-footer>
      </p-fieldset>
      <p-progressBar *ngIf="isDataLoading" mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"></p-progressBar>
      <p-table
        #dt
        [value]="companies"
        [rows]="5" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20,50]"
        [totalRecords]="totalRecords"
        scrollable="true"
        scrollHeight="400px"
        selectionMode="single"
        [style]="{'margin-top': '5px', 'margin-bottom': '5px', 'height': '400px', 'min-height': '400px'}"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 10%;">ID</th>
            <th style="width: 20%;">{{ 'COMMON.NAME' | translate }}</th>
            <th style="width: 15%;">{{ 'COMMON.COMPANY_REGISTER_NUMBER' | translate }}</th>
            <th style="width: 10%;">{{ 'COMMON.POSTAL_CODE' | translate }}</th>
            <th style="width: 16%;">{{ 'COMMON.CITY' | translate }}</th>
            <th style="width: 20%;">{{ 'COMMON.STREET' | translate }}</th>
            <th style="width: 9%;">{{ 'COMMON.IMPORT' | translate }}</th>
          </tr>
          <tr>
            <th style="width: 10%;">
              <input type="search" pInputText placeholder="Contains" (keyup)="dt.filterGlobal($event.target.value, 'contains')" style="background-color: rgb(255, 255, 186);">
            </th>
            <th style="width: 20%;">
              <input type="search" pInputText placeholder="Contains" (keyup)="dt.filterGlobal($event.target.value, 'contains')" style="background-color: rgb(255, 255, 186);">
            </th>
            <th style="width: 15%;">
              <input type="search" pInputText placeholder="Contains" (keyup)="dt.filterGlobal($event.target.value, 'contains')" style="background-color: rgb(255, 255, 186);">
            </th>
            <th style="width: 10%;">
              <input type="search" pInputText placeholder="Contains" (keyup)="dt.filterGlobal($event.target.value, 'contains')" style="background-color: rgb(255, 255, 186);">
            </th>
            <th style="width: 20%;">
              <input type="search" pInputText placeholder="Contains" (keyup)="dt.filterGlobal($event.target.value, 'contains')" style="background-color: rgb(255, 255, 186);">
            </th>
            <th style="width: 20%;">
              <input type="search" pInputText placeholder="Contains" (keyup)="dt.filterGlobal($event.target.value, 'contains')" style="background-color: rgb(255, 255, 186);">
            </th>
            <th style="width: 5%;"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company>
          <tr (dblclick)="onImportCompany(company)" [pSelectableRowDblClick]="company" [pSelectableRow]="company">
            <td style="width: 10%;">{{ company.externalId }}</td>
            <td style="width: 20%;">{{ company.name }}</td>
            <td style="width: 15%;">{{ company.companyRegistrationNumber }}</td>
            <td style="width: 10%;">{{ company.postalCode }}</td>
            <td style="width: 20%;">{{ company.city }}</td>
            <td style="width: 20%;">{{ company.street }}</td>
            <td style="width: 5%;" style="text-align: center"><button pButton type="button" icon="pi pi-download" class="ui-button-info" (click)="onImportCompany(company)"></button></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </split-area>
</split>
