import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Input, ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {EntityDataChangeMeta, EntityDataStoreService} from '../../services/entity-data-store.service';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {ExecutorService} from 'app/core/executor/executor.service';
// tslint:disable-next-line:max-line-length
import {GenericElementValidationExecutionStepsFactory} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator, EntityValidatorStatus} from '../../../validators/services/entity-validator';
import {Element} from '../../../services/element/element';
import {ModuleElement} from '../../../services/module/module-element';
import {UserSessionService} from '../../../../core/service/user-session.service';
import {ElementSaveStatus, GenericElementAbstract} from '../generic-element-abstract.component';
import {Observable, of, of as observableOf} from 'rxjs';
import {ElementType} from '../../services/ElementContext';
import {ElementsStackService} from '../../services/elements-stack.service';
import {TableColumn} from '../../../dynamic-table/shared/table-column';
import {Entity} from '../../../helpers/entity';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {map, switchMap, tap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {StaticGridFilterField} from '../abstract-generic-grid.component';
import {DynamicTableComponent} from '../../../dynamic-table/dynamic-table.component';
import {GenericElementFilterService} from '../../services/generic/filter/generic-element-filter.service';
import {Organisation} from '../../../services/organisation/organisation';
import {MemoTypeFieldDefinitionType} from '../custom/memo-field-definition-value/memo-field-definition-value.component';

const DEFAULT_VALUE = '---';

@Component({
  selector: 'app-dms-view',
  styleUrls: ['./generic-dms-view.component.scss'],
  templateUrl: './generic-dms-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GenericElementValidationExecutionStepsFactory,
    ExecutorService,
    GenericElementFilterService
  ]
})
export class GenericDmsViewComponent extends GenericElementAbstract {

  @Input() element: Element;
  @Input() moduleElement: ModuleElement;
  @Input() entity = null;
  @ViewChild(DynamicTableComponent, {static: false}) table: DynamicTableComponent;
  public staticFilters: StaticGridFilterField[] = [];
  public moduleView = null;
  public api = '';
  public branchOfficeApi = 'app/userbranchoffices/mybranchoffices/organisation/' + this.userSession.get(Organisation.LOCAL_STORAGE_NAME).id;

  public columns: (TableColumn & { moduleElementColumn?: any })[] = [];
  public totalCount = 0;
  public entities = [];
  public yearsOptions: SelectItem[] = [];
  public monthsOptions: SelectItem[] = [];
  public isLoading = false;

  public isDateFilterVisible = false;
  public isBranchOfficeFilterVisible = false;
  public selectedYearFilter = null;
  public selectedMonthFilter = null;
  public selectedBranchOfficeFilter = null;
  public selectedViewFilter = null;

  protected toolbarContextName = 'dmsView';
  public elementType: ElementType = ElementType.DmsView;

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected entityDataStore: EntityDataStoreService,
    protected modulesStateService: ModulesStateService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected genericCrudService: GenericCrudService,
    protected userSession: UserSessionService,
    protected permissionService: PermissionService,
    protected elementRef: ElementRef,
    public cdr: ChangeDetectorRef,
    protected elementsStackService: ElementsStackService,
    protected genericElementFilterService: GenericElementFilterService,
  ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      executorService, genericElementValidationExecutionStepsFactory,
      entityValidator, genericCrudService,
      userSession, permissionService, cdr
    );
  }

  onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  onChange(changeMeta: EntityDataChangeMeta): Observable<any> {
    return observableOf(null);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.elementContext = this.elementsStackService.createContext(this);
    this.elementsStackService.remove(this.elementContext).add(this.elementContext);

    this.moduleView = Entity.getValueInEmbedded(this.moduleElement, 'element.moduleView')
    this.api = `dms/views?modulView=${this.moduleView.id}`

    this.yearsOptions = this.getYearsOptions();
    this.monthsOptions = this.getMonthsOptions();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  search() {
    this.isLoading = true;
    forkJoin([
      this.genericCrudService.getEntities(`dms/documentsviews?view=${this.selectedViewFilter.id}`),
      this.genericCrudService.getEntities(`dms/viewfields?view=${this.selectedViewFilter.id}`),
    ]).pipe(switchMap((aViews: any[]) => {
      const documentViews = aViews[0]
      const memoTypeId = Entity.getValue(documentViews[0], 'memoType.id') || Entity.getValueInEmbedded(aViews[0], 'memoType.id')

      return this.genericCrudService.getEntities(`dms/dmsfiles?memoType=${memoTypeId}`, '', this.getRemoteFilters())
        .pipe(map((entities: any[]) => {
          this.entities = entities;

          return {
            entities,
            views: aViews[1]
          }
        }))
    }),
    switchMap(({ entities, views }) => {
      return this.createValueMatrix(entities, views);
    })
    ).subscribe((views: any[]) => {
      this.isLoading = false;
      this.columns = [];
      this.columns = views.map((aView) => {
        const viewMemoFieldDefinitionTemplate = Entity.getValueInEmbedded(aView, 'memoField') // memoFieldDefinitionTemplate
        const columnKey = Entity.getValue(viewMemoFieldDefinitionTemplate, 'name')
        return {
          key: columnKey,
          field: columnKey,
          viewMemoFieldDefinitionTemplate: viewMemoFieldDefinitionTemplate,
          visible: aView.visible,
          filterable: aView.filterable,
          renderType: viewMemoFieldDefinitionTemplate.dataType.name,
          header: viewMemoFieldDefinitionTemplate.name,
          filter: {
            key: columnKey,
            type: 'text',
          }
        }
      });

      this.columns.push({
        key: 'createdBy.signature',
        renderType: 'embedded',
        header: 'Created by',
        visible: true,
        filter: {
          key: 'createdBy.signature',
          type: 'text',
        }
      });

      this.moduleElement.columns = this.moduleElement.columns || [];
      for (const column of this.moduleElement.columns) {
        this.columns.push({
          key: column.name,
          header: column.name,
          renderType: 'moduleElementColumn',
          moduleElementColumn: column
        })
      }
      this.columns.concat(this.moduleElement.columns as any);
      this.onColumnsCreated()
      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  onBranchOfficeChange(event: {id: number}): void {
    this.selectedBranchOfficeFilter = event

    this.search();
  }

  onViewChange(view: {id: number}): void {
    this.selectedViewFilter = view;

    this.search();
  }

  onLocalFilter(event): void {
    this.isLoading = true;
    this.table.grid.filter(event.originalEvent.target.value, event.column.key, 'contains')

    setTimeout(() => {
      this.isLoading = false;
      this.cdr.detectChanges();
    }, 100);
  }

  doValidate(): Observable<EntityValidatorStatus> {
    return observableOf(null);
  }

  getSelectedEntity(): any {
  }

  hasChanges(checkEmbedded: boolean): boolean {
    return false;
  }

  onRefresh(): Observable<any> {
    return observableOf(null);
  }

  onSave(): Observable<ElementSaveStatus> {
    return observableOf(null);
  }

  recheckToolbarItems(): void {
  }

  protected onColumnsCreated() {
    this.isDateFilterVisible = this.columns.findIndex((aColumn) => {
      return Entity.getValue(aColumn, 'viewMemoFieldDefinitionTemplate.dataType.code') === 'date' && aColumn.filterable
    }) !== -1;

    this.isBranchOfficeFilterVisible = this.columns.findIndex((aColumn) => {
      return Entity.getValue(aColumn, 'viewMemoFieldDefinitionTemplate.dataType.code') === 'branchOffice' && aColumn.filter !== undefined
    }) !== -1;

    ChangeDetectorRefHelper.detectChanges(this);
  }

  protected getRemoteFilters() {
    const params = {
      'embedded': 'responsibleBranchOffices,createdBy,fieldDefinitionValues,fieldDefinitionValues.fieldDefinition,fieldDefinitionValues.fieldDefinition.fieldDefinitionTemplate,fieldDefinitionValues.fieldDefinition.fieldDefinitionTemplate.dataType'
    };

    if (this.selectedYearFilter) {
      params['fieldDefinitionValues.value'] = `contains:${this.selectedYearFilter}-`
      params['fieldDefinitionValues.fieldDefinition.fieldDefinitionTemplate.dataType.code'] = `equal:date`
    }

    if (this.selectedMonthFilter) {
      params['fieldDefinitionValues.value'] = `contains:-${this.selectedMonthFilter.toString().padStart(2, '0')}-`
      params['fieldDefinitionValues.fieldDefinition.fieldDefinitionTemplate.dataType.code'] = `equal:date`
    }

    if (this.selectedBranchOfficeFilter && this.selectedBranchOfficeFilter.branchOffice && this.selectedBranchOfficeFilter.branchOffice.id) {
      params['responsibleBranchOffices.id'] = `in:${this.selectedBranchOfficeFilter.branchOffice.id}`;
    }

    if (this.entity && this.entity.fqn === 'PhoenixBundle\\Entity\\LeasedEmployee' && this.entity.id) {
      params['fieldDefinitionValues.value'] = `in:${this.entity.id}`
      params['fieldDefinitionValues.fieldDefinition.fieldDefinitionTemplate.dataType.code'] = `equal:${MemoTypeFieldDefinitionType.CODE_LEASED_EMPLOYEE}`
    }

    if (this.entity && this.entity.fqn === 'PhoenixBundle\\Entity\\Customer' && this.entity.id) {
      params['fieldDefinitionValues.value'] = `in:${this.entity.id}`
      params['fieldDefinitionValues.fieldDefinition.fieldDefinitionTemplate.dataType.code'] = `equal:${MemoTypeFieldDefinitionType.CODE_CUSTOMER}`
    }

    return params;
  }

  protected getYearsOptions(): SelectItem[] {
    const years = [{value: null, label: '---'}],
      startFromYear = new Date().getFullYear();

    for (let i = 0; i <= 20; i++) {
      const year = startFromYear - i;
      years.push({ value: year, label: `${year}` });
    }

    return years;
  }

  protected getMonthsOptions(): SelectItem[] {
    return this.genericElementFilterService.getSelectableMonths();
  }

  protected createValueMatrix(entities, views): Observable<any[]> {
    const viewMemoFieldDefinitionTemplates = views.filter((aView) => {
      return this.isAssociationField(aView);
    })

    this.mapColumnsToEntityValue(entities, views)

    const observables = [];
    if (viewMemoFieldDefinitionTemplates.length > 0 && this.entities.length > 0) {
      for (const viewMemoFieldDefinitionTemplate of viewMemoFieldDefinitionTemplates) {
        const apiRoute = Entity.getValueInEmbedded(viewMemoFieldDefinitionTemplate, 'memoField.lookupFetchDatamodel.apiRoute'),
          columnKey = Entity.getValueInEmbedded(viewMemoFieldDefinitionTemplate, 'memoField.name')

        observables.push(this.genericCrudService.getEntities(apiRoute, '', {
          id: `in:${entities.filter((dmsFile) => dmsFile[columnKey] && dmsFile[columnKey] !== DEFAULT_VALUE).map((dmsFile) => {
            return dmsFile[columnKey]
          })}`
        }))
      }
    }

    if (observables.length === 0) {
      return of(views);
    }

    return forkJoin(observables).pipe(
      map((associationValues: any[]) => {
        const allValues = [].concat.apply([], associationValues)

        const valueMap = {}
        for (const value of allValues) {
          valueMap[value.id] = value
        }

        return valueMap
      }),
      map((associationValues: Record<string, any>) => {
        this.mapAssociationColumnsToEntityValue(entities, viewMemoFieldDefinitionTemplates, associationValues)

        return views;
      })
    );
  }

  protected mapColumnsToEntityValue(entities, views) {
    for (const entity of entities) {
      for (const view of views) {
        const columnKey = Entity.getValue(view, 'memoField.name')
        const fieldDefinitionValues = Entity.getValue(entity, 'fieldDefinitionValues')

        for (const fieldDefinitionValue of fieldDefinitionValues) {
          if (Entity.getValue(fieldDefinitionValue, 'fieldDefinition.fieldDefinitionTemplate.name') === columnKey) {
            entity[columnKey] = fieldDefinitionValue.value || DEFAULT_VALUE;
          }
        }
      }
    }
  }

  protected mapAssociationColumnsToEntityValue(entities, views, associationValues: Record<string, any>) {

    for (const entity of entities) {
      for (const view of views) {
        const columnKey = Entity.getValue(view, 'memoField.name')
        const columnLabel = Entity.getValue(view, 'memoField.lookupFetchDatamodelLabel')
        const fieldDefinitionValues = Entity.getValue(entity, 'fieldDefinitionValues')

        for (const fieldDefinitionValue of fieldDefinitionValues) {
          if (Entity.getValue(fieldDefinitionValue, 'fieldDefinition.fieldDefinitionTemplate.name') === columnKey) {
            let value = DEFAULT_VALUE;
            if (fieldDefinitionValue.value && associationValues[fieldDefinitionValue.value]) {
              value = Entity.getValue(associationValues[fieldDefinitionValue.value], columnLabel) || Entity.getValueFromPlaceholder(associationValues[fieldDefinitionValue.value], columnLabel);
            }
            entity[columnKey] = value
          }
        }
      }
    }
  }

  protected isAssociationField(aView) {
    const viewMemoFieldDefinitionTemplate = Entity.getValueInEmbedded(aView, 'memoField')
    return [
        MemoTypeFieldDefinitionType.CODE_AUTOCOMPLETE,
        MemoTypeFieldDefinitionType.CODE_BRANCH_OFFICE,
        MemoTypeFieldDefinitionType.CODE_LEASED_EMPLOYEE,
        MemoTypeFieldDefinitionType.CODE_CUSTOMER,
        MemoTypeFieldDefinitionType.CODE_DROPDOWN
      ].includes(viewMemoFieldDefinitionTemplate.dataType.code) &&
      viewMemoFieldDefinitionTemplate.lookupFetchDatamodel
  }
}
