
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {AbstractGenericGridComponent} from "../../../../abstract-generic-grid.component";
import {FormViewerComponent} from "../../../../../../form-viewer/form-viewer.component";
import {ExecutorActionEvent} from '../../../../../../../core/executor/service/executor-actions/executor-action-event';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-create-leased-employee-from-cv',
  templateUrl: './toolbar-item-create-leased-employee-from-cv.component.html',
  styles: [`
    ::ng-deep .ui-widget-content {
      border: 0px;
    }

    ::ng-deep .ui-dialog-title {
      text-align: left;
    }

    ::ng-deep div.ui-growl {
      left: 50%;
      top: 25%;
      right: 0;
      margin-left:-150px;
    }

    ::ng-deep span.ui-fileupload-choose {
      width: 25%;
    }

    ::ng-deep span.ui-button-text {
      padding: 0.25em 1.5em !important;
    }

    ::ng-deep button.ui-button-icon-only {
      width: 20px;
    }

    ::ng-deep .dialog-close-button-container {
      float: right;
      cursor: pointer;
      margin-top: -28px;
    }

    ::ng-deep .ui-dialog-titlebar-maximize {
      margin-right: 13px;
    }
  `]
})
export class ToolbarItemCreateLeasedEmployeeFromCvComponent extends ToolbarItemAbstract {

  public isDialogVisible = false;
  public cvFileDataURL = '';
  public cvFileName = '';
  public customElementData = {
    id: 'cvUploadFileInput',
    type: 'file-input'
  };

  @ViewChild('dialog', {static: false}) dialog;

  public constructor(
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  public click() {
    this.isDialogVisible = true;
  }

  public onCloseDialog(event) {
    this.doClose();
  }

  public doClose() {
    this.isDialogVisible = false;
  }

  public onDialogMaximize(event) {
    this.dialog.toggleMaximize(event);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  public onSelectCV(event): void {
    const files: FileList = event.files,
      fileName = files.item(0).name;

    files.item(0).toDataURL().subscribe((dataURL) => {
      this.cvFileName = fileName;
      this.cvFileDataURL = dataURL.replace('data:application/pdf;base64,', '')
        .replace('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,', '');

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  public onCreate(event): void {
    const component = this.getComponent();
    this.doClose();
    this.genericCrudService.createEntity('phoenix/leasedemployees/createfromcv', {
      dataURL: this.cvFileDataURL,
      fileName: this.cvFileName
    }).pipe(catchError((error: HttpErrorResponse) => {
      this.messageGrowlService.error(error.message);

      return observableThrowError(error);
    })).subscribe((creatorResult: {
      leasedEmployee: any,
      exception: any
    }) => {
      if (creatorResult.exception) {
        this.messageGrowlService.info(creatorResult.exception.message, 'Data saved with error!');
      } else {
        this.messageGrowlService.showDataSaved();
      }

      this.setComponentEntity(creatorResult.leasedEmployee);
      component.executeAction(ExecutorActionEvent.EntityImported, component).subscribe();
    });
  }

  public onCancel($event): void {
    this.doClose();
  }

  private setComponentEntity(leasedEmployee: any): void {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      component.setSelectedEntity(leasedEmployee);
    }

    if (component instanceof FormViewerComponent) {
      component.entity = leasedEmployee;
    }
  }
}
