import { TemplateElement } from './../template/template.element';
import { Element } from './../element/element';
import { Module } from './module';
import { ModuleElementToolbar } from './module-element-toolbar';
import { ModuleElementStatusbar } from './module-element-statusbar';
import { ModuleElementField } from './module-element-field';
import { ModuleElementPart } from './module-element-part';
import { ModuleElementFieldFilter } from './module-element-field-filter';
import { ModuleElementAction } from './module-element-action';
import { ModuleElementColumn } from './module-element-column';

interface ObjectWithId {
  id: number;
}

export interface DoubleClickAware {
  onDoubleClick: string;
  onDoubleClickTarget: string;
  onDoubleClickTargetDialogWidth: string;
  onDoubleClickTargetDialogHeight: string;
  onDoubleClickTargetModuleId?: number;
  onDoubleClickTargetElementId?: number;
  isDoubleClickTargetDefined?: boolean;
  isDoubleClickTargetDialogModal?: boolean;
}

interface EmbeddedEntities {
  element?: Element;
  master?: ObjectWithId;
  actions?: ModuleElementAction[];
  onDoubleClickTargetModule?: ObjectWithId;
  onDoubleClickTargetElement?: ObjectWithId;
  moduleElementFieldFilters?: ModuleElementFieldFilter[];
}

export class ModuleElement implements DoubleClickAware {

  public static readonly DOUBLE_CLICK_DETAILS = 'details';
  public static readonly DOUBLE_CLICK_ELEMENT = 'element';
  public static readonly DOUBLE_CLICK_MODULE = 'module';

  public static readonly DOUBLE_CLICK_TARGET_NEW_TAB = 'new_tab';
  public static readonly DOUBLE_CLICK_TARGET_CURRENT_TAB = 'current_tab';
  public static readonly DOUBLE_CLICK_TARGET_DIALOG = 'dialog';

  public static readonly SORTING_DIRECTION_ASCENDING = 'asc';
  public static readonly SORTING_DIRECTION_DESCENDING = 'desc';

  public static readonly SORTING_TYPE_ALPHABETIC = 'alphabetic';
  public static readonly SORTING_TYPE_NUMERIC = 'numeric';

  id: number;
  name: string;
  parent: ModuleElement;
  parentId: number;
  children: ModuleElement[];
  module: Module;
  moduleId: number;
  templateElement: TemplateElement;
  hasTabs: boolean;
  element: Element;
  master: ModuleElement;
  onDoubleClickTargetModule: Module;
  onDoubleClickTargetDialogWidth: string;
  onDoubleClickTargetDialogHeight: string;
  isDoubleClickTargetDialogModal: boolean;
  onDoubleClickTargetElement?: Element;
  onDoubleClickTargetModuleId?: number;
  onDoubleClickTargetElementId?: number;
  toolbarItems: ModuleElementToolbar[];
  statusbarItems: ModuleElementStatusbar[];
  fields: ModuleElementField[];
  columns: ModuleElementColumn[];
  parts: ModuleElementPart[];
  actions: ModuleElementAction[];
  itemdblclick: string;
  onDoubleClick: string;
  onDoubleClickTarget: string;
  orderPosition: number;
  collapseDirection: string;
  gutterSize: number;
  percentageSize: number;
  pixelSize: number;
  direction: string;
  showPermissions: boolean;
  masterFilterField?: string;
  masterFilterValueField?: string;
  firstSortingField?: string;
  firstSortingDirectionField?: string;
  firstSortingTypeField?: string;
  secondSortingField?: string;
  secondSortingDirectionField?: string;
  secondSortingTypeField?: string;
  collectiveAgreementField?: string;
  isCollectiveAgreementFromLocalStorage?: boolean;
  salaryClassificationField?: string;
  fieldReferenceAssociationType?: string;
  fieldReferenceAssociation?: string;
  fieldWorkingMode?: string;
  fieldHasCompanyAgreement?: string;
  globalFieldWageVisible?: boolean;
  globalFieldFactorVisible?: boolean;
  globalFieldFactorisedWageVisible?: boolean;
  globalFieldWageLabel?: string;
  globalFieldFactorLabel?: string;
  globalFieldFactorisedWageLabel?: string;
  globalFieldSection?: string;
  moduleElementFieldFilters?: ModuleElementFieldFilter[];
  docuwareOnlyRead?: number;
  isMaster: boolean = false;
  ignoreValidations = false;
  selectFirst: boolean = true;
  isChangesCheckActivated = true;
  isAutoSaveActivated = false;
  isPermissionAware = false;
  isLocked: boolean = false;
  customApiRoute: string;
  statusBarItems?: any;
  isCollapsed?: boolean;
  label?: any;
  isAdminToolbarItemsButtonVisible?: boolean;
  visibleAdminToolbarItems?: number[]
  areFiltersVisible?: boolean;

  _embedded?: EmbeddedEntities;

  hasMaster() {
    return this._embedded && this._embedded.master && this._embedded.master.id > 0;
  }
}
