
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { AbstractAsyncValidator } from './abstract-async-validator';
import { ValidationStatus } from '../services/validation';
import { cloneDeep } from 'lodash';
import { Constants } from 'app/constants';

export class SocialSecurityNumberValidator extends AbstractAsyncValidator {

  public getConstraint(): any {
    const fields = this.getParams();

    const valueParam = this.getParam('value');
    if (valueParam) {
      valueParam.value = this.getFullSocialSecurityNumber();
    } else {
      fields.push({
        'key': 'value',
        'value': this.getFullSocialSecurityNumber()
      });
    }

    return {
      'name': 'socialSecurityNumber',
      'fields': fields
    };
  }

  public isValid(value: any): boolean {
    return true;
  }

  public getErrorTranslateKey(value: any): string {
    return 'SOCIAL_SECURITY_NUMBER_INVALID';
  }

  public getErrorTranslateParams(value: any): any {
    return {
      'value': this.getEntityFieldValue()
    };
  }

  public validate(): Observable<ValidationStatus> {
    let observable = observableOf({
      isValid: true,
      errorTranslated: '',
      errorTranslateKey: '',
      errorTranslateParams: null,
      field: this.getField(),
      value: this.getEntityFieldValue()
    });

    if (this.shouldValidate(this.getEntity())) {
        const entityToCheck = cloneDeep(this.getEntity());

        observable = this.getGenericCrudService().createEntity(`${Constants.APP_API_ROUTE}/validate`, {
            'constraint': this.getConstraint(),
            'entity': this.getEntityHydrator().hydrate(entityToCheck)
        }, false).pipe(catchError((response, caught) => {
            const errors = response.error.errors;

            return observableOf({
                isValid: false,
                errorTranslated: this.getErrorTranslated(errors),
                errorTranslateKey: this.getErrorTranslateKey(this.getEntityFieldValue()),
                errorTranslateParams: this.getErrorTranslateParams({}),
                field: this.getField(),
                value: this.getEntityFieldValue()
            });
        }));
    }

    return observable;
  }

  public shouldValidate(entity: any): boolean {
    return entity && this.getParamValue('iso3') && this.getParamValue('property') && this.getEntityFieldValue();
  }

  private getFullSocialSecurityNumber() {
    let socialSecurityNumber = this.getEntity()['socialSecurityNumber'];
    if (!this.getEntity()['isFullSocialSecurityNumberVisible']) {
      let birthDate = this.getEntity()['birthDate'] || this.getEntity()['birthday'];
      if (birthDate) {
        birthDate = new Date(birthDate.toString());
        socialSecurityNumber += this.getTwoDigitString(birthDate.getDate()) + this.getTwoDigitString(birthDate.getMonth() + 1) +
          this.getTwoDigitString(birthDate.getFullYear());
      }
    }
    return socialSecurityNumber;
  }

  private getTwoDigitString(value){
    value = value.toString();
    if(value.length < 2){
      value = '0' + value;
    }
    if(value.length > 2){
      value = value.substr(value.length - 2);
    }
    return value;
  }
}
