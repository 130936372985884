<div fxLayout="row">
  <p-fieldset [legend]="element.label" [styleClass]="!element.showLabel ? 'hide-fieldset-label' : ''" #elementItem>
    <ng-container *ngFor="let elementChild of elements">
      <div fxLayout="row" fxLayoutAlign="start center">
        <label fxFlex="40%" [hidden]="isGlobalFieldHidden(elementChild)">{{elementChild.label}}</label>
        <div class="input-item" style="position: relative;" fxFlex="60%">
          <ng-container [ngSwitch]="true">

            <app-generic-calendar
              *ngSwitchCase="elementChild.typeElement == 'date'"
              (onInputChangeValid)="onDateChange(elementChild.name, $event)"
              (onSelect)="onDateChange(elementChild.name, $event)"
              [disabled]="isGlobalFieldReadOnly(elementChild)"
              [value]="globalFields[elementChild.name]"
              [yearRange]="'1950:2050'"
              [mask]="'99.99.9999'"
              [dateFormat]="'dd.mm.yy'"
              [showOnFocus]="true"
            ></app-generic-calendar>

            <input
              *ngSwitchCase="elementChild.typeElement == 'integer' || elementChild.typeElement === 'distance'"
              type="number"
              [style]="{'width':'100%'}"
              (change)="onChange(elementChild.name, $event)"
              [(ngModel)]="globalFields[elementChild.name]"
              [hidden]="isGlobalFieldHidden(elementChild)"
              [disabled]="isGlobalFieldReadOnly(elementChild)"
            >

            <app-element-input-global-field-period
              *ngSwitchCase="elementChild.typeElement === 'duration'"
              [hidden]="isGlobalFieldHidden(elementChild)"
              [period]="globalFields[elementChild.name]"
              (onChange)="onPeriodChange(elementChild.name, $event)"
              [style]="{'width':'100%'}"
            ></app-element-input-global-field-period>

            <input
              *ngSwitchCase="elementChild.typeElement === 'text' || elementChild.typeElement === 'string'"
              type="text"
              [style]="{'width':'100%'}"
              (change)="onChange(elementChild.name, $event)"
              [(ngModel)]="globalFields[elementChild.name]"
              [hidden]="isGlobalFieldHidden(elementChild)"
              [disabled]="isGlobalFieldReadOnly(elementChild)"
            >

            <p-inputMask
              *ngSwitchCase="elementChild.typeElement === 'time'"
              (onChange)="onChange(elementChild.name, $event)"
              (onComplete)="onChange(elementChild.name, $event)"
              [(ngModel)]="globalFields[elementChild.name]"
              [hidden]="isGlobalFieldHidden(elementChild)"
              [disabled]="isGlobalFieldReadOnly(elementChild)"
              mask="99:99"
            ></p-inputMask>

            <p-checkbox
              pInputCheckbox
              *ngSwitchCase="elementChild.typeElement === 'boolean'"
              binary="true"
              (onChange)="onChange(elementChild.name, $event)"
              [(ngModel)]="globalFields[elementChild.name]"
              [hidden]="isGlobalFieldHidden(elementChild)"
              [disabled]="isGlobalFieldReadOnly(elementChild)"
            ></p-checkbox>

            <p-dropdown
              *ngSwitchCase="elementChild.typeElement === 'enum'"
              [options]="elementChild.allowedValues"
              [autoWidth]="false"
              [autoDisplayFirst]="false"
              (onChange)="onChange(elementChild.name, $event)"
              [(ngModel)]="globalFields[elementChild.name]"
              [style]="{'width':'100%'}"
              appendTo="body"
              [hidden]="isGlobalFieldHidden(elementChild)"
              [disabled]="isGlobalFieldReadOnly(elementChild)"
              #inputElement
            ></p-dropdown>

            <div *ngSwitchDefault="">Not implemented yet (element-input.component)... -{{elementChild.typeElement}}-</div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="entity && entity[wageAmountField] && element.globalFieldWageVisible">
      <div fxLayout="row" fxLayoutAlign="start center">
        <label fxFlex="40%">{{element.globalFieldWageLabel}}:</label>
        <div fxFlex="60%" style="font-weight: bold">{{wageAmountLoading == false ? (entity[wageAmountField] | decimal) : 'loading...'}}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="entity && entity[referenceAssociationFactorField] && element.globalFieldFactorVisible">
      <div fxLayout="row" fxLayoutAlign="start center">
        <label fxFlex="40%">{{element.globalFieldFactorLabel}}:</label>
        <div fxFlex="60%" style="font-weight: bold">{{entity[referenceAssociationFactorField] | decimal}}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="entity && entity[referenceAssociationFactorField] && entity[wageAmountField] && wageAmountLoading == false && element.globalFieldFactorisedWageVisible">
      <div fxLayout="row" fxLayoutAlign="start center">
        <label fxFlex="40%">{{element.globalFieldFactorisedWageLabel}}:</label>
        <div fxFlex="60%" style="font-weight: bold">{{getFactorisedWage() | decimal}}</div>
      </div>
    </ng-container>
  </p-fieldset>
</div>
