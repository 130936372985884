import {forkJoin as observableForkJoin, Observable, of as observableOf, throwError as observableThrowError} from 'rxjs';

import {catchError, map, takeUntil} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {env} from './../../../environments/environment';
import {Element, ElementAction, ElementInputCheckbox, ElementInputSwitch, Form} from './models/index';
import {ElementFormCrudService} from './../services/element-form/element-form.crud.service';
import {ToolbarItem} from '../services/element/toolbar-item';
import {ModuleElement} from '../services/module/module-element';
import {ElementFormEntityService} from '../services/element-form/element-form.entity.service';
import {MessageGrowlService} from '../../core/message/message-growl.service';
import {ElementSaveStatus, GenericElementAbstract} from '../content-renderer/elements/generic-element-abstract.component';
import {ComponentService} from '../content-renderer/services/component-highlight-stack.service';

import {FormElementValueChange, FormService} from './form.service';
import {FormViewerService} from './form-viewer.service';
import {ApiBuilderService} from '../services/api.builder.service';
import {GenericCrudService} from '../services/generic-crud.service';
import {FormAction, FormActionsEvents} from './models/form';
import {Datamodel} from '../services/datamodel/datamodel';
import {DatamodelCrudService} from '../services/datamodel/datamodel.crud.service';
import {ElementInputDate} from './models/element-input-date';
import {ElementsStackService} from '../content-renderer/services/elements-stack.service';
import {EntityFactoryService} from '../services/entity-factory.service';
import {LocationService} from '../services/location.service';
import {ElementContext, ElementType, MasterEntityConfig} from '../content-renderer/services/ElementContext';
import {MessageService} from '../../core/message/message.service';
import {EntityDirtyStoreService} from '../content-renderer/services/entity-dirty-store.service';
import {TranslateService} from '@ngx-translate/core';
import {EntityChangedMeta, EntityDataChangeMeta, EntityDataStoreService} from '../content-renderer/services/entity-data-store.service';
import {DateHelper} from '../helpers/date.helper';
import {HttpErrorResponse} from '@angular/common/http';
import {ElementInput, ElementInputAssociation} from './models/element';
import {FormActionsService} from './actions/services/form-actions.service';
import {RequestCachingService} from '../services/request-caching.service';
import {LoggerService} from '../content-renderer/services/logger/logger.service';
import {ModulesStateService} from '../content-renderer/services/modules-state.service';
import {ToolbarItemCheckService} from '../content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {DraftAddAware} from 'app/shared/generic-element.typings';
import {cloneDeep} from 'lodash';
import {AbstractGenericGridComponent} from '../content-renderer/elements/abstract-generic-grid.component';
import {EventHandlerService} from '../../core/events/event/event-handler.service';
import {ExecutorService} from '../../core/executor/executor.service';
import {EntityHydrator} from 'app/shared/services/entity-hydrator.service';
import {GenericElementValidationExecutionStepsFactory} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator, EntityValidatorStatus} from '../validators/services/entity-validator';
import {Guid} from 'guid-typescript';
import {EntityDraftStoreService} from '../content-renderer/services/entity-draft-store.service';
import {EntityStatus} from '../services/entity/entity-status';
import {ExecutorActionEvent} from 'app/core/executor/service/executor-actions/executor-action-event';
import {ExecutorActionsService} from 'app/core/executor/service/executor-actions/executor-actions.service';
import {ElementContextInspectorService} from '../content-renderer/services/element-context-inspector.service';
import {JobContext} from '../../core/job-runner/context/job.context';
import {JobContainerService} from '../../core/job-runner/job-container.service';
import {RunnableEventRegistry} from '../../core/job-runner/type/runnable-event.registry';
import {LoadSlaveDataCondition} from '../job-runner/condition/load-slave-data.condition';
import {LoadSlaveDataJob} from '../job-runner/job/load-slave-data.job';
import {LocalStorageDataService} from '../services/local-storage-data.service';
import {LayoutService} from '../services/layout-service';
import {FormViewerFocusService} from './service/form-viewer-focus.service';
import {EventHelper} from '../helpers/event.helper';
import {GenericGridGlobalFilterService} from '../content-renderer/elements/generic-grid/services/generic-grid-global-filter.service';
import {GenericCrudRequestOptions} from '../services/generic-crud-request-options.service';
import {MasterEntityImportEvent} from '../services/event/event/master-entity-import-event';
import {GenericDialogModuleService} from '../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {Entity} from '../helpers/entity';
import {QuickLink} from '../services/module/quick-link';
import {PermissionService} from '../services/permission/permission.service';
import {LockingService} from '../services/locking.service';
import {GenericElementEmbeddedService} from '../content-renderer/services/generic/generic-element-embedded.service';
import {EntityManagerService} from '../../core/service/entity-manager/entity-manager.service';
import {ChangeDetectorRefHelper} from '../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../core/service/user-session.service';
import {DialogService} from 'primeng/api';

export interface FormControlObject {
  formControlName: string;
  formControlValue: any;
}

/**
 * @description Main component for our form viewer
 * @example <caption>How to use the form viewer component.</caption>
 *  <app-form-viewer
 *    [form]="<Form> | <Observable<[Form, any[]]>>"
 *    [formId]="<number>"
 *    [(entity)]="<any> | <Observable<any>>"
 *    [hideLoading]="<boolean> (false)"
 *    [editMode]="<boolean> (false)"
 * ></app-form-viewer>
 * @export
 * @class FormViewerComponent
 * @implements {OnInit}
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.scss'],
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    FormViewerFocusService,
    GenericGridGlobalFilterService,
    DialogService,
  ]
})
// @todo Why not an GenericElementForm which is including toolbars and all the element stuff so that the form viewer can be standalone
export class FormViewerComponent extends GenericElementAbstract implements OnDestroy, DraftAddAware {

  public elementType: ElementType = ElementType.Form;

  public formIsLoading = true;

  protected toolbarContextName = 'formViewerComponent';

  @ViewChild('formElement', {static: false}) formElement: ElementRef;

  /**
   * @description Form to load
   * @type {Form}
   * @memberOf FormViewerComponent
   */
  private formObj: Form;

  public emptyEntity: any;

  @Input() isPart = false;
  @Input() isSlave = false;
  @Input() isInSubView = false;
  @Input() emitEntitySaved = true;
  @Input() performRefreshOnSave = true;

  @HostListener('keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {

      if (event.shiftKey && EventHelper.isTabPressed(event)) {
        // this.formViewerFocusService.focusPrevious();
        //
        // event.preventDefault();
        // return false;
      }

      if (!event.shiftKey && EventHelper.isTabPressed(event)) {
        // this.formViewerFocusService.focusNext();
        //
        // event.preventDefault();
        // return false;
      }
  }

  @Input()
  get form(): Form {
    return this.formObj;
  }

  @Output() formChange: EventEmitter<Form> = new EventEmitter();

  set form(val: Form) {
    this.formObj = val;
    this.formChange.emit(this.formObj);
  }

  private selectedElementObj: Element;

  @Input()
  get selectedElement(): Element {
    return this.selectedElementObj;
  }

  @Output() selectedElementChange: EventEmitter<Element> = new EventEmitter();

  set selectedElement(val: Element) {
    this.selectedElementObj = val;
    this.selectedElementChange.emit(this.selectedElementObj);
  }

  @Output() formGroupInit: EventEmitter<FormGroup> = new EventEmitter();

  formGroup: FormGroup;

  /**
   * @description Module element the form is bound to.
   * @type {ModuleElement}
   * @memberOf FormViewerComponent
   */
  @Input() moduleElement: ModuleElement;

  /**
   * @description Load a form by id
   * @type {number}
   * @memberOf FormViewerComponent
   */
  @Input() formId: number;


  /**
   * @description Create new entity form the form and save it using this url.
   * @type {string}
   * @memberOf FormViewerComponent
   */
  @Input() saveUrl: string;

  private entityObj: any;

  /**
   * @description Is entity without id in form changed
   * @type {boolean}
   */
  public isNewEntityDirty = false;

  @Input()
  get entity() {
    return this.entityObj;
  }

  @Output() entityChange = new EventEmitter();

  set entity(val) {
    if (val && val.fqn !== this.getElementDatamodelEntityName() && !this.isSisterDatamodel(val.fqn)) {
      return;
    }

    if (val && !val.id) {
      this.isNewEntityDirty = false;
    }

    this.changeFormEntity(val)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  @Input() hideLoading = false;

  /**
   * @description Set edit mode for selelect and drag & drop events. This will output an error if it is setted
   * to true outside from the an form editor instance.
   * @todo check if this is safe cause user could set this attribute with tools like firebug or google chrome dev tools
   * @memberOf FormViewerComponent
   */
  @Input() editMode = false;

  @ViewChild('dialog', {static: false}) dialog;

  private unmappedEntityElements: string[] = [];

  public element;

  private topToolbarItems: ToolbarItem[] = [];
  private statusbarItems: ToolbarItem[] = [];

  private formInitTime: number;

  showDialogAddAddress = false;
  showDialogOpenModule = false;

  actionParams: any;

  formBackground: string;

  public inputElements: ElementInput[] = [];

  private isFormEntityChangeInProgress = false;

  public static getFormControlName(elObjectId: string, fieldName?: string): string {
    if (elObjectId && fieldName) {
      if(fieldName.indexOf('.') !== -1){
        let fieldNameArray = fieldName.split('.');
        fieldName = fieldNameArray.join('-p-');
      }
      return `${fieldName}_h_r_f_e_${elObjectId}`;
    } else if (elObjectId && !fieldName) {
      return `no-dm_h_r_f_e_${elObjectId}`.trim();
    }
  }

  public constructor(
    public elementRef: ElementRef,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    private elementFormCrudService: ElementFormCrudService,
    private elementFormEntityService: ElementFormEntityService,
    private messageGrowlService: MessageGrowlService,
    private translationService: TranslateService,
    private formService: FormService,
    private formViewerService: FormViewerService,
    private formBuilder: FormBuilder,
    private apiRouteBuilderService: ApiBuilderService,
    private datamodelCrudService: DatamodelCrudService,
    protected genericCrudService: GenericCrudService,
    public elementsStackService: ElementsStackService,
    private locationService: LocationService,
    private entityFactory: EntityFactoryService,
    private messageService: MessageService,
    private entityDirtyStore: EntityDirtyStoreService,
    private entityDataStore: EntityDataStoreService,
    private entityDraftStore: EntityDraftStoreService,
    private translateService: TranslateService,
    private formActionsService: FormActionsService,
    private requestCachingService: RequestCachingService,
    private logger: LoggerService,
    private toolbarItemCheckService: ToolbarItemCheckService,
    public cdr: ChangeDetectorRef,
    private formViewerFocusService: FormViewerFocusService,
    protected eventHandlerService: EventHandlerService,
    protected executorService: ExecutorService,
    protected entityHydrator: EntityHydrator,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected executorActionsService: ExecutorActionsService,
    protected elementContextInspectorService: ElementContextInspectorService,
    protected jobContainerService: JobContainerService,
    protected userSession: UserSessionService,
    protected layoutService: LayoutService,
    protected genericGridGlobalFilterService: GenericGridGlobalFilterService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected permissionService: PermissionService,
    protected lockingService: LockingService,
    protected embedded: GenericElementEmbeddedService,
    protected entityManager: EntityManagerService,
    public dialogService: DialogService,
  ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      executorService, genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService,
      userSession, permissionService, cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    if (this.moduleElement && this.moduleElement.id) {
      this.elementsStackService.remove(this.elementsStackService.findById(this.moduleElement.id));
    }
  }

  public isEntityDirty(): boolean {

    if (this.entity && this.entity.id) {
      return this.entityDirtyStore.isDirty(this.entity) || this.entity[EntityStatus.ENTITY_CHANGED_FLAG];
    }else if (this.entity && this.entityDirtyStore.fetchCreated(this.entity)){
      return this.entityDirtyStore.isDirty(this.entity);
    }

    return this.isNewEntityDirty;
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return this.isEntityDirty();
  }

  public getSelectedEntity(): any {
    return this.entity || null;
  }

  protected createContext() {
    const isSlave = this.moduleElement && typeof this.moduleElement.master !== 'undefined'
      && this.moduleElement.master !== null && this.moduleElement.master.id > 0;

    const isSubView = this.locationService.hasParam('parent-module');

    const elementContext = new ElementContext(
      this.moduleElement.id,
      ElementType.Form,
      this,
      this.moduleElement,
      !isSlave,
      isSlave,
      !isSlave,
      isSubView,
      this.isPart,
      null,
      this.masterElementContext,
      null,
      null,
      null,
      this.isDialog,
      this.moduleElement.isMaster
    );

    if (isSubView) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.locationService.getParam('id');
      elementConfig.name = this.locationService.hasParam('master-entity')
        ? this.locationService.getParam('master-entity') : this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.

      elementContext.addMasterEntity(elementConfig);
    }

    // Now let's remove and re-add the grid context if it is already there:
    this.elementsStackService.remove(elementContext).add(elementContext);

    return elementContext;
  }

  onComponentInit() {
    this.formLoading(true);
    this.runOnInitJobs();

    this.formViewerFocusService.formComponent = this;

    this.formInitTime = new Date().getTime();

    if (this.moduleElement && this.moduleElement.id) {
      this.executorActionsService
        .registerModuleElementActions(this.moduleElement)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
      this.elementContext = this.createContext();

      if (this.entityObj) {
        const condition = new LoadSlaveDataCondition();
        condition.setPayload({
          component: this,
          entity: this.entityObj
        });
        this.jobContainerService.registerJob(
          new LoadSlaveDataJob().setIsImmutable(true),
          condition
        );
      }
    }

    this.subscriptions.push(
      // @todo this part should be called by the parent component or better just update the component binding
      this.entityDataStore.entityChanged$.pipe(takeUntil(this.unsubscribe)).subscribe((meta: EntityChangedMeta) => {
        if (meta.entity && this.getElementDatamodelEntityName() === meta.entity['fqn'] && meta.replaceFormEntity) {
          this.entity = meta.entity;

          this.replaceMasterEntity(this.entity);
        }

        this.toolbarItemCheckService.check(this);
      }),
      this.entityDataStore.entityValueChanged$.pipe(takeUntil(this.unsubscribe)).subscribe((entityDataChangeMeta: EntityDataChangeMeta) => {
        if (entityDataChangeMeta.entity
          && (this.isSisterDatamodel(entityDataChangeMeta.entity.fqn) || this.getElementDatamodelEntityName() === entityDataChangeMeta.entity['fqn'])
          && this.form && this.entity
          && this.entity[EntityStatus.ENTITY_DRAFT_FLAG]
          === entityDataChangeMeta.entity[EntityStatus.ENTITY_DRAFT_FLAG]) {

          this.onChange(entityDataChangeMeta).subscribe((entity) => {
            this.onValidate().subscribe();
          });

          this.toolbarItemCheckService.check(this);
        }
      }),
      this.entityDataStore.entityDeleted$.pipe(takeUntil(this.unsubscribe)).subscribe((entity) => {
        if (entity && this.getElementDatamodelEntityName() === entity['fqn'] && this.entity && entity.id === this.entity.id) {
          if (this.emptyEntity) {
            this.entityObj = this.cloneEntity();
            this.entityChange.emit(this.entityObj);
          }
        }
      }),
      // @todo this part should be called by the parent component or better just update the component binding
      this.entityChange.pipe(takeUntil(this.unsubscribe)).subscribe((entity) => {
        if (this.formGroup) {
          this.formService.onFormEntityChanged(entity);
          this.patchFormGroupDisabled();
          this.patchFormGroupValues();

          // Now handle entity changed event:
          this.formActionsService.fireFormAction(FormActionsEvents.ON_ENTITY_CHANGED, this.form, this.entityObj, this.formGroup, this.element, this);

          this.replaceMasterEntity(this.entity);

          if (this.entity) {
            this.onValidate()
              .pipe(takeUntil(this.unsubscribe))
              .subscribe();

            setTimeout(this.handleElementsAction.bind(this, 'onEntityChanged'), 500);
          }

          ChangeDetectorRefHelper.detectChanges(this);
        }
      }),
      // Subscribe to formChanged
      this.formService.formChanged.pipe(takeUntil(this.unsubscribe)).subscribe((form: Form) => this.initForm()),
      this.formService.formElementValueChange$.pipe(takeUntil(this.unsubscribe)).subscribe((formElementValueChange: FormElementValueChange) => {
        if (this.entity && formElementValueChange.entity
          && this.entity[EntityStatus.ENTITY_DRAFT_FLAG]
          === formElementValueChange.entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
          this.onFormValueChange(formElementValueChange);
        }
      }),
      this.formService.formValidate$.pipe(takeUntil(this.unsubscribe)).subscribe((form: Form) => {
        if (this.form && this.form === form && this.entity) {
          this.onValidate()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
        }
      })
    );

    this.initForm();

    this.executeAction(ExecutorActionEvent.Init, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  runOnInitJobs() {
    const context = new JobContext();
    context.component = this;
    context.event = RunnableEventRegistry.PreInit;
    context.identifier = Guid.create().toString();

    this.jobContainerService.runRelevantJobs(context);
  }

  initForm() {
    // @todo omg what a bad if condition...
    if (this.form instanceof Observable || this.formId) {
      let observableForm: Observable<[Form, any[]]>;
      if (this.form && this.form instanceof Observable) {
        observableForm = <Observable<[Form, any[]]>>this.form;
      } else if (!this.form && this.formId) {
        const first: Observable<Form> = this.elementFormCrudService.getElementForm(this.formId);
        const second: Observable<any[]> = this.elementFormCrudService.getElementFormFields(this.formId);

        observableForm = <Observable<[Form, any[]]>>observableForkJoin(first, second);
      }

      this.loadForm(observableForm);
    } else {
      this.formService.form = this.form;
      this.buildFormGroupFromElements();
    }
  }

  public setEntity(entity: any): this {
    this.entityObj = entity;
    return this;
  }

  public setIsNewEntityDirty(isDirty: boolean): this {
    this.isNewEntityDirty = isDirty;
    return this;
  }

  loadForm(form: Observable<[Form, any[]]>) {
    this.subscriptions.push(
      form.pipe(
        takeUntil(this.unsubscribe))
        .subscribe((data: [Form, any[]]) => {
        if (data) {
          const loadedForm = data[0];
          const loadedElements = data[1];

          this.element = loadedForm;

          this.initToolbarItems();

          this.getQuickLinks()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((quickLinks: QuickLink[] = []) => {
              for (const quickLink of quickLinks) {
                this.addToolbarItem(QuickLink.toToolbarItem(quickLink), ToolbarItem.POSITION_TOP);
              }
            });

          this.form = new Form({
            id: loadedForm.id,
            title: loadedForm.label,
            content: loadedForm.content,
            elements: loadedElements,
            datamodel: loadedForm._embedded.datamodel,
            actions: loadedForm._embedded.actions
          });

          this.formService.form = this.form;

          this.buildFormGroupFromElements();

          if (!this.isFormEntityChangeInProgress && !this.entity && !this.getElementContext().isSlave &&
            !this.isMasterElementEntitySelected()
          ) {
            this.loadEmptyEntity()
              .pipe(takeUntil(this.unsubscribe))
              .subscribe( (entity) => {
              const context = new JobContext();
              context.component = this;
              context.event = RunnableEventRegistry.PostInit;
              context.identifier = Guid.create().toString();

              this.jobContainerService.runRelevantJobs(context);
            });
          }
          this.entityObj = this.entity;
          if (this.entityObj && !this.entityObj[EntityStatus.ENTITY_DRAFT_FLAG]) {
            this.entityObj[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
          }
          this.entityChange.emit(this.entityObj);
        }
      })
    );
  }

  loadEmptyEntity(): Observable<any> {
    this.emptyEntity = {};

    if (!this.element || !this.element.datamodel) {
      return observableOf(null);
    }

    return Observable.create((observer) => {
      this.entityFactory
        .buildComponentEntity(this).pipe(
        takeUntil(this.unsubscribe))
        .subscribe((entity) => {
          this.emptyEntity = entity;

          this.entity = this.cloneEntity();

          observer.next(this.entity);
          observer.complete();
        });
    });
  }

  protected cloneEntity(): any {
    const clonedEntity = cloneDeep(this.emptyEntity);

    clonedEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
    this.entityFactory.assignMasterEntities(clonedEntity, this.getElementContext().getMasterEntities());

    return clonedEntity;
  }

  public getElementDatamodelEntityName(): string {
    let name = '';

    if (this.moduleElement && this.moduleElement.element && this.moduleElement.element.datamodel) {
      const nameParts = this.moduleElement.element.datamodel.name.split('.');

      name = `${nameParts[0]}\\Entity\\${nameParts[1]}`;
    }

    return name;
  }

  public isSisterDatamodel(entityFqn: string): boolean {
    const fqnMap = ['Organisation', 'BranchOffice', 'User', 'Todo'];

    if (this.moduleElement && this.moduleElement.element && this.moduleElement.element.datamodel) {
      const nameParts = this.moduleElement.element.datamodel.name.split('.');

      return fqnMap.indexOf(nameParts[1]) > -1 && entityFqn.indexOf(nameParts[1]) > -1;
    }

    return false;
  }

  buildFormGroupFromElements() {
    const observableArray = [];

    if (this.form instanceof Observable) {
      observableArray.push(this.form);
    }

    if (this.entity instanceof Observable) {
      observableArray.push(this.form);
    }

    if (observableArray.length > 0) {
      observableForkJoin(...observableArray)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
        this.buildFormGroupObject();
      });
    } else {
      this.buildFormGroupObject();
    }
  }

  buildFormGroupObject() {

    if (!this.form || !this.form.elements) {
      return;
    }

    const elements = this.form.elements,
      formGroupObject = {}, initialDisableElements = [];

    this.inputElements = [];

    const addFormControl = (element: Element) => {
      this.entityValidator.addFormValidations(this.getElementDatamodelEntityName(), element);

      if (element.type === 'input' || element.type === 'globalFields') {
        const formControlObject = this.getFormControlNameAndValue(element),
          formControlName = formControlObject.formControlName,
          formControlValue = formControlObject.formControlValue;

        element.setEntity(this.entity)
          .setValue(formControlValue);

        let isDisabled = false;
        if (element.type === 'input' && element instanceof ElementInput) {
          this.inputElements.push(element);
          isDisabled = this.isInputElementDisabled(element);
        }

        const formControlArray = [];

        // initial value
        formControlArray.push({value: formControlValue, disabled: isDisabled});

        // add validators to control
        formGroupObject[formControlName] = formControlArray;
      } else {
        if (element.canHaveChildren) {
          element['children'].map(addFormControl);
        }
      }
    };

    elements.map(addFormControl);
    this.formGroup = this.formBuilder.group(formGroupObject);

    this.formGroupInit.emit(this.formGroup);
    this.formLoading(false);

    this.formActionsService.fireFormAction(FormActionsEvents.ON_INIT, this.form, this.entityObj, this.formGroup, this.element, this);

    if(this.moduleElement) {
      this.executeAction(ExecutorActionEvent.FormRendered, this)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    }
  }

  private isInputElementDisabled(element: ElementInput): boolean {
    return !this.entity || element.disabled;
  }

  private getFormControlNameAndValue(element: Element): FormControlObject {
    let formControlName: string,
          formControlValue: any;

    if (element['datamodelField']) {
      const datamodelField: string = element['datamodelField'] && element['datamodelField'].replace(/\./g, '-p-') || '';
      formControlName = FormViewerComponent.getFormControlName(element.objectHashId, datamodelField);

      if (/\-p\-/.test(formControlName) && this.entity) {
        const formControlNames = datamodelField.split('-p-');

        formControlNames.map((controlName: string) => {
          if (this.entity && this.entity['_embedded'] && this.entity['_embedded'][controlName] && formControlValue === undefined) {
            formControlValue = this.entity['_embedded'][controlName];
          }

          if (
            formControlValue !== undefined &&
            formControlValue[controlName] === undefined &&
            formControlValue['_embedded'] !== undefined &&
            formControlValue['_embedded'][controlName] !== undefined
          ) {
            formControlValue = formControlValue['_embedded'][controlName];
          }

          if (formControlValue !== undefined && formControlValue[controlName] !== undefined) {
            formControlValue = formControlValue[controlName];
          }
        });

        if (typeof (formControlValue) === 'object') {
          formControlValue = undefined;
        }
      } else {
        formControlValue = this.entity && this.entity[datamodelField] || null;
      }
    } else {
      formControlName = FormViewerComponent.getFormControlName(element.objectHashId, undefined);
      formControlValue = null;
    }

    if (element instanceof ElementInputDate && formControlValue && DateHelper.isIso(formControlValue)) {
      formControlValue = new Date(formControlValue);
    }

    return {
      'formControlName': formControlName,
      'formControlValue': formControlValue
    };
  }

  public patchFormGroupValues(): void {
    for (const inputElement of this.inputElements) {
      const formControlObject = this.getFormControlNameAndValue(inputElement),
          formControlName = formControlObject.formControlName,
          formControlValue = formControlObject.formControlValue;

      inputElement.setEntity(this.entity)
          .setValue(formControlValue);

      if (inputElement instanceof ElementInputAssociation) {
        continue;
      }

      this.formGroup.get(formControlName).patchValue(formControlValue);
    }
  }

  public patchFormGroupDisabled(): void {

    for (const inputElement of this.inputElements) {
      inputElement.disabled = !this.entity;
    }

    for (const key in this.formGroup.controls) {
      if (!this.entity) {
        this.formGroup.controls[key].disable();
      } else {
        this.formGroup.controls[key].enable();
      }
    }
  }

  private formLoading(start = false) {
    if (!this.hideLoading && start === true) {
      this.formIsLoading = true;
    }

    if (!this.hideLoading && start === false) {
      this.formIsLoading = false;
    }
  }

  public getFormLeftBorder() {
    let borderString = '';

    if (this.entity && this.entity[EntityStatus.ENTITY_INVALID_FLAG]) {
      borderString = '5px solid #d9534f';
    }

    return borderString;
  }

  public getFormRightBorder() {
    let borderString = '';

    if (this.entity && this.entityDirtyStore.isDirty(this.entity)) {
      borderString = '4px solid #4444ff';
    }

    return borderString;
  }

  onFormValueChange(meta: FormElementValueChange): void {

    if (meta.triggerChange && this.entityDataStore.isEntityDeleted(this.entity)) {
      this.messageGrowlService.error(
        this.translationService.instant('DIALOG_MESSAGES.NOT_ABLE_TO_CHANGE_HEADER')
      );
    } else if (meta.triggerChange && this.isEntityLocked(this.entity)) {
      this.messageGrowlService.error(
        this.translationService.instant('DIALOG_MESSAGES.NOT_ABLE_TO_CHANGE_HEADER')
      );
    }else if (meta) {
      this.doChangeFormValue(meta);
    }
  }

  private doChangeFormValue(meta: FormElementValueChange) {
    const value = meta.formControlValue,
      element = meta.element,
      entityValue = meta.entityValue,
      datamodelField = element.datamodelField,
      formControlName = meta.formControlName,
      formControlOptions = meta.formControlOptions,
      elementComponent = meta.component,
      updateFormComponent = meta.updateFormComponent;

    const oldEntityValue = Entity.getValue(this.entity, datamodelField);

    this.changeEntityValue(entityValue, oldEntityValue, datamodelField)
        .changeFormGroupValue(value, formControlName, formControlOptions, updateFormComponent)
        .changeFormElementValue(entityValue, element);

    if (!this.entity.id && meta.triggerChange) {
      this.isNewEntityDirty = true;
    }

    this.formActionsService
      .fireFormAction(
        FormActionsEvents.ON_RUNTIME_VALUE_CHANGED,
        this.form,
        this.entity,
        this.formGroup,
        element,
        this
      );

    if (meta.triggerChange) {
      this.entityDirtyStore.forceStore(this.entity);

      this.getEntityDataStore().onEntityValueChanged(this.getElementDataModelApiRoute(), {
        entity: this.entity,
        datamodelField: datamodelField,
        value: entityValue,
        oldValue: oldEntityValue
      });

      this.formActionsService.fireFormAction(FormActionsEvents.ON_VALUE_CHANGED, this.form, this.entity, this.formGroup, element, this);

      if (elementComponent) {
        /** instanceof ComponentSelectedOptionAware */
        if (elementComponent.selectedOption) {
          elementComponent.filterActionAndHandleIt('onselect');
        }else if (typeof elementComponent === 'object' && (elementComponent.element instanceof ElementInputCheckbox || elementComponent.element instanceof ElementInputSwitch)) {
          if(entityValue){
            elementComponent.filterActionAndHandleIt('onselect');
          }else{
            elementComponent.filterActionAndHandleIt('onunselect');
          }
        }
        elementComponent.filterActionAndHandleIt('onInputValueChanged');
      }

      this.notifySlaves(ExecutorActionEvent.EntityValueChanged, meta)
          .notifyMaster(ExecutorActionEvent.EntityValueChanged, meta);

      this.executeAction(ExecutorActionEvent.EntityValueChanged, {
        component: this,
        entityDataChangeMeta: meta
      }).subscribe();
      this.formService.onFormElementAfterValueChanged(meta);
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private changeEntityValue(value: any, oldValue: any, datamodelField: string): this {
    this.entityManager.persist(this.entity, {property: datamodelField, oldValue: oldValue, newValue: value, force: true });

    if (value === null && this.entity._embedded && this.entity._embedded[datamodelField]) {
      this.entity._embedded[datamodelField] = null;
    }

    return this;
  }

  private changeFormGroupValue(value: any, formControlName: string, formControlOptions: Object = {}, updateFormComponent = true) {
    if (this.formGroup && this.formGroup.get(formControlName) && updateFormComponent) {
      this.formGroup.get(formControlName).setValue(value, formControlOptions);
    }

    return this;
  }

  private changeFormElementValue(value: any, element: Element) {
    element.setValue(value);
  }

  public onFormValidated(status: any) {
    const isValid = status.isValid;
    let errorFieldsDatamodelNames = [];

    // mark form
    this.entity[EntityStatus.ENTITY_INVALID_FLAG] = isValid || this.entity.tmpIsDeleted;

    if (!isValid && status.errorFields.length > 0) {
      errorFieldsDatamodelNames = status.errorFields.map((element) => { return element.datamodelField; });
    }

    // mark invalid controls
    for (const key in this.formGroup.controls) {
      if (this.formGroup.get(key)) {
        const controlName = key,
          datamodelFieldName = controlName.substr(0, controlName.indexOf('_h_r_f_e'));
        let errors = null;

        if (errorFieldsDatamodelNames.includes(datamodelFieldName)) {
          errors = { 'incorrect': true };
        }

        this.formGroup.controls[controlName].setErrors(errors);
      }
    }
  }

  private isDialogActionParamSet(actionParamName: string): boolean {
    return this.actionParams && this.actionParams[actionParamName] && this.actionParams[actionParamName].value;
  }
  public onDialogOpenModuleSelect(event) {

  }


  public onDialogAddAddress(value: App.Form.Data.AddAddress) {

    // (GU) :: REALLY BAD, optimise once we remove getFormControlName() hack! There will be no need for loop then...
    for (const formControlName in this.formGroup.controls) {
      if (formControlName.indexOf('_h_r_f_e') === -1) {
        continue;
      }
      const dataModelField = formControlName.substr(0, formControlName.indexOf('_h_r_f_e'));

      if (this.isDialogActionParamSet('countryDatamodelField')
        && this.actionParams.countryDatamodelField.value === dataModelField && value.country && this.formGroup.get(formControlName)) {
        this.formGroup.get(formControlName).setValue({ id: value.country.id });
      }

      if (this.isDialogActionParamSet('provinceDatamodelField')
        && this.actionParams.provinceDatamodelField.value === dataModelField && value.province && this.formGroup.get(formControlName)) {
        this.formGroup.get(formControlName).setValue({ id: value.province.name });
      }

      if (this.isDialogActionParamSet('cityDatamodelField')
        && this.actionParams.cityDatamodelField.value === dataModelField && value.city && this.formGroup.get(formControlName)) {
        this.formGroup.get(formControlName).setValue(value.city.city);
      }

      if (this.isDialogActionParamSet('streetDatamodelField')
        && this.actionParams.streetDatamodelField.value === dataModelField && value.street && this.formGroup.get(formControlName)) {
        this.formGroup.get(formControlName).setValue(value.street.street);
      }

      if (this.isDialogActionParamSet('postalCodeDatamodelField')
        && this.actionParams.postalCodeDatamodelField.value === dataModelField && value.street && this.formGroup.get(formControlName)) {
        this.formGroup.get(formControlName).setValue(value.street.postalCode);
      }
    }

    this.actionParams = undefined;
  }

  public onSave(): Observable<ElementSaveStatus> {
    // First assume we do need to perform a refresh:
    this.performRefresh = false;
    return Observable.create((observer) => {

      if (this.isSaveable()) {

        this.doSave()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((saveStatus) => {
            this.onRefresh()
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(() => {
                console.log('refreshed formviewer component.');
              });
            observer.next({status: true, content: saveStatus.content});
            observer.complete();
          });
      } else {
        this.performRefresh = false;
        observer.next({
          status: true
        });
        observer.complete();
      }
    });
  }

  public onAddDraft(): Observable<any> {
    this.performRefresh = true;
    return Observable.create((observer) => {

      if (this.getElementContext().getMasterElementContext() !== null &&
        this.getElementContext().getMasterElementContext().component instanceof AbstractGenericGridComponent
      ) {
        const masterElementContextComponent = this.getElementContext().getMasterElementContext().component;

        let draftEntity = this.entity;

        if (draftEntity && !draftEntity.id && !draftEntity[EntityStatus.ENTITY_DRAFT_FLAG]) {
          const draft = cloneDeep(this.entity);
          draft[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG] = true;

          masterElementContextComponent.addCreatedEntityDraft(draft);

          draftEntity = draft;
        }

        this.genericDialogModuleService.persistHide();
        masterElementContextComponent.embedToParent(draftEntity);

        this.toolbarItemCheckService.check(this);

        this.importEntitiesIntoSlaves(draftEntity);
      } else {
        this.messageGrowlService.error('Form entity cannot add draft as parent grid element doesn\'t exist!');

        observer.next(status);
        observer.complete();
      }
    });
  }

  protected importSlaveEntities(elementContext: ElementContext, entity: any) {
    for (const slaveContext of elementContext.getSlaveElementContexts()) {
      if (slaveContext.component instanceof AbstractGenericGridComponent
        && slaveContext.component.element && slaveContext.component.element.datamodel
        && slaveContext.component.element.datamodel.entityCollectionName) {
        const createdEntities = slaveContext.component.getCreatedEntities(true);

        for (const createdEntity of createdEntities) {
          this.pushToMasterEntityCollection(entity, slaveContext.component.element.datamodel.entityCollectionName, createdEntity);
        }

        const updateEntities = slaveContext.component.getUpdatedEntities(true);

        for (const updatedEntity of updateEntities) {
          this.pushToMasterEntityCollection(entity, slaveContext.component.element.datamodel.entityCollectionName, updatedEntity);
        }
      }
    }
  }

  protected pushToMasterEntityCollection(masterEntity: any, collectionName: string, entity: any) {
    if (!masterEntity.hasOwnProperty(collectionName)) {
      masterEntity[collectionName] = [];
    }

    if (entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]
      || this.findEntityById(masterEntity[collectionName], entity) !== null) {
      return;
    }

    masterEntity[collectionName].push(this.entityHydrator.hydrate(entity));
    masterEntity[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG] = true;
  }

  protected findEntityById(collection: any[], entity) {
    for (const collectionEntity of collection) {
      if ((collectionEntity.id && collectionEntity.id === entity.id)
        || collectionEntity[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        return collectionEntity;
      }
    }

    return null;
  }

  public onAfterSave(): Observable<any> {
    this.performRefresh = true;
    this.entityDirtyStore.remove(this.entity);
    this.requestCachingService.removeByExpression(this.getElementDataModelApiRoute());

    if (this.elementContext.masterElementContext && this.elementContext.masterElementContext.component) {
      this.elementContext.masterElementContext.component.onRefresh()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    }

    this.toolbarItemCheckService.check(this);

    return observableOf(null);
  }

  public onChange(entityDataChangeMeta: EntityDataChangeMeta): Observable<any> {
    this.entity[entityDataChangeMeta.datamodelField] = entityDataChangeMeta.value;
    this.entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;

    return observableOf(this.entity);
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return this.onValidate();
  }

  public onRefresh(): Observable<any> {
    if (this.performRefresh) {
      if (this.entity && this.entity.id) {
        return this.changeFormEntity(this.entity);
      } else if (this.entity && !this.entity.id && !this.getElementContext().isSlaveContext()) {
        return this.loadEmptyEntity();
      }
    }

    return observableOf(null);
  }

  private validate(entity): Observable<EntityValidatorStatus> {
    return Observable.create((observer) => {
      const entityIsDirty = this.isEntityDirty();

      const callNonValidObserver = (body: string) => {
        observer.next({
          isValid: false,
          error: body
        });
      };

      if (this.entityDataStore.isEntityDeleted(this.entity) || !entityIsDirty) {
        // this.logger.info(this.translationService.instant('DIALOG_MESSAGES.IMPOSSIBLE_TO_EDIT_ENTITY_BODY'), {
        //   entity: this.entity
        // });

        this.entityDataStore.isEntityDeleted(this.entity)
          ? callNonValidObserver(this.translationService.instant('DIALOG_MESSAGES.IMPOSSIBLE_TO_EDIT_ENTITY_BODY'))
        : callNonValidObserver(this.translationService.instant('DIALOG_MESSAGES.ENTIY_LOCKED_BODY'));
      }

      const me = this,
        entityUrl = this.extractEntityUrl();

      if (!entityUrl) {
        callNonValidObserver(
          'Save URL is not defined!'
        );
        observer.complete();
      }

      if (!this.formViewerService.isValid(this.form)) {
        callNonValidObserver(
          'Form elements are invalid!'
        );
        observer.complete();
      }

      if (this.entity.tmpIsDeleted) {
        callNonValidObserver(
          this.translateService.instant('VALIDATON_MESSAGE.ERRORS.ENTITY_IS_TEMP_DELETED')
        );
        observer.complete();
      }

      this.onValidate()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((status) => {
        this.onFormValidated(status);

        observer.next(status);
        observer.complete();
      });
    });
  }

  private isEntityLocked(entity): boolean {
    return entity[EntityStatus.ENTITY_LOCKED_FLAG];
  }

  private doSave(): Observable<ElementSaveStatus> {
    const entityUrl = this.extractEntityUrl();

    return Observable.create((observer) => {
      this.elementFormEntityService.saveEntity(entityUrl, this.entity).pipe(
        takeUntil(this.unsubscribe),
        catchError((response: HttpErrorResponse) => {
          this.performRefresh = false;
          observer.next({
            status: false,
            content: null
          });
          observer.complete();

          return observableThrowError(response);
        }))
        .subscribe((entity) => {
          const updatePerformed = this.entity['id'] ? true : false;
          entity[EntityStatus.ENTITY_CHANGED_FLAG] = false;

          this.setEntity(entity);

          if (this.performRefreshOnSave) {
            this.performRefresh = true;
          }

          this.entityDirtyStore.remove(this.entity);

          this.messageGrowlService.success('Entity saved!');

          if (this.emitEntitySaved) {
            this.entityDataStore.onEntityChanged({
              entity: entity
            });
          }

          const context = new JobContext();
          context.component = this;
          context.event = RunnableEventRegistry.PostSave;
          context.identifier = Guid.create().toString();

          this.jobContainerService.runRelevantJobs(context);

          this.toolbarItemCheckService.check(this);

          observer.next({
            status: true,
            content: entity
          });
          observer.complete();
        });
    });
  }

  protected triggerDeleted(selectedEntity: any) {
    if (selectedEntity && (selectedEntity.deletedAt || selectedEntity.tmpIsDeleted)) {
      this.messageGrowlService.error(
        this.translationService.instant('DIALOG_MESSAGES.IMPOSSIBLE_TO_EDIT_ENTITY_BODY'),
        this.translationService.instant('DIALOG_MESSAGES.NOT_ABLE_TO_CHANGE_HEADER')
      );
    }
  }

  protected extractEntityUrl(): string {
    let entityUrl = '';

    if (this.entity) {
      entityUrl = (this.entity._links && this.entity._links.self && this.entity._links.self.href) ? this.entity._links.self.href : this.saveUrl;

      if (entityUrl === this.saveUrl && this.entity.id) {
        entityUrl += (this.entity && this.entity.id) ? `/${this.entity.id}` : '';
      }
    }

    // @todo This hack is necessary cause we are getting entity._links.self.href including /api/. But we are already setting in our env file
    // this part so we have to remove it. Just for a little error controle we are only removing it if we find 'api' at the url in our
    // env config.
    if (env.apiUrl.substr(-3).replace(/[^\w\s]/gi, '') === 'api' && entityUrl.substr(0, 4).replace(/[^\w\s]/gi, '') === 'api') {
      entityUrl = entityUrl.substr(5);
    }

    if (!entityUrl && this.formObj && this.formObj.datamodel) {
      entityUrl = this.formObj.datamodel.apiRoute;
      if (this.entity && this.entity.id) {
        entityUrl += `/${this.entity.id}`;
      }
    }

    return entityUrl;
  }

  protected getFormIdSalt(): string {
    return `formContainer_${this.form.id}${this.form.name}${this.formInitTime}`;
  }

  protected getFormStyle() {
    return {
      'width': this.form.width || false,
      'height': this.form.height || false,
      'maxWidth': this.form.maxWidth || false,
      'text-align': this.form.align || false,
      'overflow': 'auto'
    };
  }

  public getFormLoadingError(): ErrorMessageObject[] {
    const errorText: ErrorMessageObject[] = [];

    if (!this.form && !this.formId) {
      errorText.push({
        message: `No form or formId given`,
        detail: `Please add [form]="" as observable or [formId]="" to load it by the component.`
      });
    }

    if (this.entity && this.unmappedEntityElements && this.unmappedEntityElements.length !== 0) {
      errorText.push({
        message: `Following fields are not mapped to entity`,
        detail: `<ul><li>${this.unmappedEntityElements.join('</li><li>')}</li></ul>`
      });
    }

    return (errorText.length > 0 ? errorText : undefined);
  }

  getToolbarExtraParams() {
    return {
      'formViewerComponent': this
    };
  }

  onFormViewerCancel() {
    if (this.entity.id) {
      this.changeFormEntity(this.entity)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    } else {
      this.loadEmptyEntity()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    }

    const stack = this.elementsStackService.findById(this.moduleElement.id);
  }

  public getApiRoute() {
    let apiRoute = this.getElementDataModelApiRoute();

    if (!apiRoute) {
      apiRoute = this.apiRouteBuilderService.getApiRoute(this.entity._links.self.href);
    }

    return apiRoute;
  }

  private changeFormEntity(entity: any): Observable<any> {
    this.isFormEntityChangeInProgress = true;

    if (entity && entity.id) {
      const apiRoute = this.getApiRoute();

      let embedded = this.embedded.getEmbeddedAsString(this);

      if (typeof entity.branchOffices !== 'undefined') {
      }

      const urlParams = {
        'showDeleted': true,
        'showExpired': true,
        'embedded': embedded,
        'ignoreBranchOffice': this.genericGridGlobalFilterService.getGlobalFilterValue(GenericCrudRequestOptions.IGNORE_BRANCH_OFFICE,
          this.moduleElement.moduleId,  ''
        )
      };

      return this.genericCrudService.getEntity(apiRoute, entity.id, '', urlParams).pipe(
        catchError((response) => {
          this.isFormEntityChangeInProgress = false;

          return observableThrowError(response);
        }),
        map((loadedEntity) => {
          loadedEntity = Entity.extractEmbedded(loadedEntity);

          if (typeof entity.branchOffices !== 'undefined') {
            loadedEntity.branchOffices = entity.branchOffices;
          }

          this.entityObj = this.entityDirtyStore.replace(loadedEntity);
          this.entityChange.emit(this.entityObj);

          this.elementsStackService.setSelectedEntity(this.moduleElement.id, this.entityObj);
          this.triggerAfterEntityChange();
          this.updateModuleStateEntity();

          this.isFormEntityChangeInProgress = false;
          return this.entityObj;
      }));

    } else {
      this.entityObj = entity;
      this.entityChange.emit(this.entityObj);

      this.triggerAfterEntityChange();

      this.isFormEntityChangeInProgress = false;

      return observableOf(this.entityObj);
    }
  }

  private triggerAfterEntityChange(): void {
    this.elementsStackService.setSelectedEntity(this.moduleElement.id, this.entityObj);
    this.triggerSlaves(this.entityObj);
    this.triggerDeleted(this.entityObj);

    this.notifySlaves(ExecutorActionEvent.EntityChanged)
      .notifyMaster(ExecutorActionEvent.EntityChanged);

    this.executeAction(ExecutorActionEvent.EntityChanged, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
    this.getQuickLinks()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((quickLinks: QuickLink[] = []) => {
        for (const quickLink of quickLinks) {
          this.addToolbarItem(QuickLink.toToolbarItem(quickLink), ToolbarItem.POSITION_TOP);
        }
      });
  }

  filterActionAndHandleIt(command: string, event?: any) {
    if (this.formObj && this.formObj.actions.length > 0) {
      this.formObj.actions
        .filter((action: FormAction) => (action.command && action.command.toLowerCase() === command.toLowerCase()))
        .map((action: FormAction) => this.handleAction(action, event));
    }
  }

  handleAction(action: ElementAction, event?) {
    if (action && action.className) {
      switch (action.className) {
        case 'FormActionFormColorFromDatamodelSource':
          const datamodelField: string = (action.params['datamodelField'] && action.params['datamodelField'].value)
            ? action.params['datamodelField'].value : '';

          let dm: Datamodel;

          if (this.entityObj && action.params['datamodel'] && action.params['datamodel'].value
            && this.datamodelCrudService && this.formViewerService && datamodelField) {

            const datamodelFieldValue = this.entityObj[datamodelField];
            dm = new Datamodel(action.params['datamodel'].value);

            this.datamodelCrudService.getDatamodelRecords(dm)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((dmRecords: any[]) => {
              let color = undefined;

              if (typeof datamodelFieldValue !== 'undefined') {
                const formFieldValue = (typeof datamodelFieldValue.id !== 'undefined') ? datamodelFieldValue.id : datamodelFieldValue;

                for (const record of dmRecords) {
                  if (record.id == formFieldValue) {
                    color = record.color;
                    if (record.color.charAt(0) !== '#') {
                      /* tslint:disable:no-bitwise */
                      color = '#' + ('000000' + (record.color >>> 0).toString(16)).slice(-6);
                      /* tslint:enable:no-bitwise */
                    }
                    this.formBackground = {...color};
                    this.cdr.detectChanges();
                  }
                }
              }
            });
          }
          break;
        default:
          console.log('no idea what to do', action, event);
          break;
      }
    }
  }

  private handleElementsAction(actionName: string): void {

    for (const element of this.inputElements) {
      this.formService.handleElementsAction(actionName, element);
    }
  }

  private isMasterElementEntitySelected(): boolean {
    const masterContext = this.elementContext.getMasterElementContext();
    let isSelected = false;

    if (masterContext && masterContext.component instanceof AbstractGenericGridComponent) {
      isSelected = masterContext.component.getSelectedEntity();
    }

    return isSelected;
  }

  private replaceMasterEntity(entity: any) {
    if (this.getElementContext().getMasterElementContext() &&
      this.getElementContext().getMasterElementContext().component instanceof AbstractGenericGridComponent &&
      this.getElementDatamodelEntityName() === this.getElementContext().getMasterElementContext().component.getElementDatamodelEntityName()
    ) {
      this.getElementContext().getMasterElementContext().component.replaceEntity(this.entity);
      this.getElementContext().getMasterElementContext().component.resetSelectedEntity();
      ChangeDetectorRefHelper.detectChanges(this.getElementContext().getMasterElementContext().component);
    }
  }

  private updateModuleStateEntity(): void {
    const moduleState = this.modulesStateService.getByComponent(this);
    if (moduleState.entity && this.entity && moduleState.entity.id === this.entity.id) {
      moduleState.entity = this.entityObj
    }
  }

  private isSaveable(): boolean {
    const context: ElementContext = this.getElementContext();

    return this.entity &&
      (this.isEntityDirty() || this.elementContextInspectorService.isContextMasterAndSlavesAreDirty(context));
  }

  public onDialogMaximize(event) {
    this.dialog.toggleMaximize(event);
    window.dispatchEvent(new Event('resize'));
  }

  public getInputElementComponent(formComponentName){
    return this.formService.getComponent(formComponentName);
  }

  private importEntitiesIntoSlaves(masterEntity): void {
    const slaveContexts: ElementContext[] = this.elementContext ? this.elementContext.getSlaveElementContexts() : [];

    for (const slaveContext of slaveContexts) {
      const component = slaveContext.component;

      if (component instanceof AbstractGenericGridComponent && masterEntity
        && component.element && component.element.datamodel && component.element.datamodel.entityCollectionName) {
        const collectionName = component.element.datamodel.entityCollectionName;

        if (masterEntity[collectionName] instanceof Array) {
          for (const updatedEntity of masterEntity[collectionName]) {
            component.replaceEntity(updatedEntity);
          }
        }

        ChangeDetectorRefHelper.detectChanges(component);
      }
    }
  }
}
