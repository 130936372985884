import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { ToolbarItem } from '../../../../services/element/toolbar-item';

import { ToolbarItemSave } from '../items/components/save/toolbar-item-save.component';
import { ToolbarItemPrint } from '../items/components/print/toolbar-item-print.component';
import { ToolbarItemRefresh } from '../items/components/refresh/toolbar-item-refresh.component';
import { ToolbarItemCancel } from '../items/components/cancel/toolbar-item-cancel.component';
import { ToolbarItemShowExpired } from '../items/components/show-expired/toolbar-item-show-expired.component';
import { ToolbarItemShowDeleted } from '../items/components/show-deleted/toolbar-item-show-deleted.component';
import { ToolbarItemShowWithoutMasterFilterField } from '../items/components/show-without-master-filter-field/toolbar-item-show-without-master-filter-field.component';
import { ToolbarItemAdd } from '../items/components/add/toolbar-item-add.component';
import { ToolbarItemDelete } from '../items/components/delete/toolbar-item-delete.component';
import { ToolbarItemDetails } from '../items/components/details/toolbar-item-details.component';
import { ToolbarItemEdit } from '../items/components/edit/toolbar-item-edit.component';
import { ToolbarItemOpenElement } from '../items/components/open-element/toolbar-item-open-element.component';
import { ToolbarItemOpenModule } from '../items/components/open-module/toolbar-item-open-module.component';
import { ToolbarItemCollectiveContractManageParameters } from '../items/components/collective-contract-parameters/toolbar-item-collective-contract-manage-parameters.component';
import { ToolbarItemUploadDocument } from '../items/components/upload-document/toolbar-item-upload-document.component';
import { ToolbarItemWordExport } from '../items/components/word-export/toolbar-item-word-export.component';
import { ToolbarItemCopy } from '../items/components/copy/toolbar-item-copy.component';
import { ToolbarItemReorderColumns } from "../items/components/reorder-columns/toolbar-item-reorder-columns.component";
import { ToolbarItemFormCancel } from '../items/components/form/toolbar-item-form-cancel.component';
import { ToolbarItemFormNew } from '../items/components/form/toolbar-item-form-new.component';
import { ToolbarItemFormNext } from '../items/components/form/toolbar-item-form-next.component';
import { ToolbarItemFormPrevious } from '../items/components/form/toolbar-item-form-previous.component';
import { ToolbarItemFormLast } from '../items/components/form/toolbar-item-form-last.component';
import { ToolbarItemFormFirst } from '../items/components/form/toolbar-item-form-first.component';
import { ToolbarItemFormCounter } from '../items/components/form/toolbar-item-form-counter.component';
import { ToolbarItemCompanySearch } from '../items/components/company-search/toolbar-item-company-search.component';
import { ToolbarItemFormAddAddress } from '../items/components/form/add-address/toolbar-item-form-add-address.component';
import { ToolbarItemOpenLink } from "../items/components/open-link/toolbar-item-open-link.component";
import { ToolbarItemDeleteFilters } from '../items/components/delete-filters/toolbar-item-delete-filters.component';
import { ToolbarItemSendSms } from "../items/components/notification/toolbar-item-send-sms.component";
import { ToolbarItemBack } from "../items/components/back/toolbar-item-back.component";
import { ToolbarItemDraftSave } from 'app/shared/content-renderer/elements/generic-toolbar/items/components/draft-save/toolbar-item-draft-save.component';
import { ToolbarItemDraftDelete } from 'app/shared/content-renderer/elements/generic-toolbar/items/components/draft-delete/toolbar-item-draft-delete.component';
import { ToolbarItemWordExportConfiguration } from '../items/components/word-export-configuration/toolbar-item-word-export-configuration.component';
import { ToolbarItemBulkAddComponent } from '../items/components/bulk-add/toolbar-item-bulk-add.component';
import { ToolbarItemOpenFilters } from '../items/components/open-filters/toolbar-item-open-filters.component';
import { ToolbarItemEntityImportDialog } from '../items/components/entity-import/toolbar-item-entity-import-dialog.component';
import { ToolbarItemEntityImport } from '../items/components/entity-import/toolbar-item-entity-import.component';
import { ToolbarItemSalaryTypeImportDialog } from '../items/components/salary-type-import-dialog/toolbar-item-salary-type-import-dialog.component';
import { ToolbarItemSalaryTypeSplit } from '../items/components/salary-type-split/salary-type-split.component';
import {ToolbarItemTakeAddressToMasterComponent} from '../items/components/import-elected-to-master/toolbar-item-take-address-to-master.component';
import {ToolbarItemImportMultiselectedToMasterComponent} from '../items/components/import-multiselected-to-master/toolbar-item-import-multiselected-to-master.component';
import {ToolbarItemOpenModuleAssignmentsComponent} from '../items/components/open-module/assignments/toolbar-item-open-module-assignments.component';
import {ToolbarItemAddWhenComponentIsNotChangedComponent} from '../items/components/add-when-component-is-not-changed/toolbar-item-add-when-component-is-not-changed.component';
import {ToolbarItemSetOrderOfferSendStateComponent} from '../items/components/orderOfferState/toolbar-item-set-order-offer-send-state.component';
import {ToolbarItemSetOrderOfferConfirmedStateComponent} from '../items/components/orderOfferState/toolbar-item-set-order-offer-confirmed-state.component';
import {ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent} from '../items/components/salary-type-create-articles-from-selection/toolbar-item-salary-type-create-articles-from-selection.component';
import {ToolbarItemOpenNewOfferComponent} from '../items/components/open-new-offer/toolbar-item-open-new-offer.component';
import {ToolbarItemOpenNewOrderComponent} from '../items/components/open-new-order/toolbar-item-open-new-order.component';
import {ToolbarItemGenerateWorkDatesComponent} from '../items/components/assignment/toolbar-item-generate-work-dates.component';
import {ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent} from '../items/components/open-module/assignments/toolbar-item-open-module-assignments-leased-employee.component';
import {ToolbarItemOpenModuleAssignmentsSelectedLeasedEmployeeComponent} from '../items/components/open-module/assignments/toolbar-item-open-module-assignments-selected-leased-employee.component';
import {ToolbarItemOpenModuleJobAdvertisementComponent} from '../items/components/open-module/job-advetisement/toolbar-item-open-module-job-advertisement.component';
import {ToolbarItemOpenModuleContractOfEmploymentComponent} from '../items/components/open-module/contract-of-employment/toolbar-item-open-module-contract-of-employment.component';
import {ToolbarItemOpenModuleContractOfEmployment2Component} from '../items/components/open-module/contract-of-employment/toolbar-item-open-module-contract-of-employment2.component';
import {ToolbarItemPhoneProjectCreatorComponent} from '../items/components/phone-project-creator/toolbar-item-phone-project-creator.component';
import {ToolbarItemCreateLeasedEmployeeFromCvComponent} from "../items/components/create-leased-employee-from-cv/toolbar-item-create-leased-employee-from-cv.component";
import {ToolbarItemCaWageCalculationComponent} from '../items/components/collective-agreement/wage-calculation/toolbar-item-ca-wage-calculation.component';
import {ToolbarReportExportComponent} from '../items/components/report-export/toolbar-report-export.component';
import {ToolbarItemCalculationResultComponent} from '../items/components/collective-agreement/calculation-result/toolbar-item-calculation-result.component';
import {ToolbarItemUpdateLeasedEmployeeByDocumentComponent} from '../items/components/create-leased-employee-from-cv/toolbar-item-update-leased-employee-by-document.component';
import {ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent} from '../items/components/open-questionnaire/toolbar-item-open-questionnaire-by-phone-project-group.component';
import {ToolbarItemExecuteStepComponent} from '../items/components/execute-step/toolbar-item-execute-step.component';
import {ToolbarItemUnDeleteComponent} from '../items/components/un-delete/toolbar-item-un-delete.component';
import {ToolbarItemImportMultiselectedInModuleContext} from '../items/components/import-multiselected-to-master/toolbar-item-import-multiselected-in-module-context.component';
import {ToolbarItemShowIgnoreBranchOfficeComponent} from '../items/components/show-ignore-branch-office/toolbar-item-show-ignore-branch-office.component';
import {ToolbarItemUploadXmlDocumentComponent} from '../items/components/upload-document/toolbar-item-upload-xml-document.component';
import {ToolbarItemAddPreCalculationArticleComponent} from '../items/components/pre-calculation-article/toolbar-item-add-pre-calculation-article.component';
import {ToolbarItemSavePreCalculationArticleComponent} from '../items/components/pre-calculation-article/toolbar-item-save-pre-calculation-article.component';
import {ToolbarItemUploadLeasedEmployeeDocumentComponent} from '../items/components/upload-document/toolbar-item-upload-leased-employee-document.component';
import {ToolbarItemExpertSearchComponent} from '../items/components/expert-search/toolbar-item-expert-search.component';
import {ToolbarItemCaContractOfEmploymentComponent} from '../items/components/collective-agreement/contractOfEmployment/toolbar-item-ca-contract-of-employment.component';
import {ToolbarItemAddPreCalculationComponent} from '../items/components/add-pre-calculation/toolbar-item-add-pre-calculation.component';
import {ToolbarItemOpenQuickLinkComponent} from '../items/components/open-quick-link/toolbar-item-open-quick-link.component';
import {ToolbarItemSyncSalaryTypesComponent} from '../items/components/collective-agreement/salary-type-sync/toolbar-item-sync-salary-types.component';
import {ToolbarItemOpenModuleSelectedSkillsOfLeasedEmployeeComponent} from '../items/components/open-module/leased-employee/toolbar-item-open-module-selected-skills-of-leased-employee.component';
import {ToolbarItemAddSkillsToLeasedEmployeeComponent} from '../items/components/open-module/leased-employee/toolbar-item-add-skills-to-leased-employee.component';
import {ToolbarItemPreCalculationShowFactorsComponent} from '../items/components/pre-calculation-article/toolbar-item-pre-calculation-show-factors.component';
import {ToolbarItemReleasePreCalculationComponent} from '../items/components/pre-calculation-article/toolbar-item-release-pre-calculation.component';
import {ToolbarItemImportCloudPrinterComponent} from '../items/components/import-cloud-printer/toolbar-item-import-cloud-printer.component';
import {ToolbarItemUploadGkkDocumentComponent} from "../items/components/upload-document/toolbar-item-upload-gkk-document.component";
import {ToolbarItemSimpleSearchComponent} from '../items/components/simple-search/toolbar-item-simple-search.component';
import {ToolbarItemImportSpecialAllowancesComponent} from '../items/components/pre-calculation-article/toolbar-item-import-special-allowances.component';
import {ToolbarItemSyncArticlesComponent} from '../items/components/pre-calculation-article/toolbar-item-sync-articles.component';
import {ToolbarItemKsvCompanySearchComponent} from '../items/components/ksv-company-search/toolbar-item-ksv-company-search.component';
import {ToolbarItemBdmExportComponent} from "../items/components/bdm-export/toolbar-item-bdm-export.component";
import {ToolbarItemSaveGroupedArticleComponent} from '../items/components/pre-calculation-article/toolbar-item-save-grouped-article.component';
import {ToolbarItemResetPreCalculationArticleComponent} from '../items/components/pre-calculation-article/toolbar-item-reset-pre-calculation-article.component';
import {ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent} from '../items/components/leased-employee/toolbar-item-show-or-hide-contract-of-employment-exit-information.component';
import {ToolbarItemCaWorkhourFlattenComponent} from '../items/components/collective-agreement/workhour-flatten/toolbar-item-ca-workhour-flatten.component';
import {ToolbarItemExportReportComponent} from '../items/components/lga/toolbar-item-export-report.component';
import {ToolbarItemCaWorkhourUnflattenComponent} from '../items/components/collective-agreement/workhour-unflatten/toolbar-item-ca-workhour-unflatten.component';
import {ToolbarItemWorkHourMonthlyGridShowWageAndInvoiceColumnsComponent} from '../items/components/work-hour/toolbar-item-work-hour-monthly-grid-show-wage-and-invoice-columns.component';
import {ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent} from '../items/components/work-hour/toolbar-item-work-hour-monthly-grid-toggle-between-wage-and-invoice-columns.component';
import {ToolbarItemWorkHourMonthlyGridRecalculateComponent} from '../items/components/work-hour/toolbar-item-work-hour-monthly-grid-recalculate.component';
import {ToolbarItemCaWorkhourInvoiceFlattenComponent} from '../items/components/collective-agreement/workhour-invoice-flatten/toolbar-item-ca-workhour-invoice-flatten.component';
import {ToolbarItemCaWorkhourInvoiceUnflattenComponent} from '../items/components/collective-agreement/workhour-invoice-unflatten/toolbar-item-ca-workhour-invoice-unflatten.component';
import {ToolbarItemStornoContractOfEmploymentComponent} from "../items/components/open-module/contract-of-employment/toolbar-item-storno-contract-of-employment.component";
import {ToolbarItemCaBulkCalculateComponent} from '../items/components/collective-agreement/workhour-bulk-calculate/toolbar-item-ca-bulk-calculate.component';
import {ToolbarItemStornoInvoiceComponent} from "../items/components/invoice/toolbar-item-storno-invoice.component";
import {ToolbarItemDownloadBulkDocuwareComponent} from "../items/components/docuware/toolbar-item-download-bulk-docuware.component";
import {ToolbarItemWorkHourMonthlyGridSelectionComponent} from "../items/components/work-hour-monthly/toolbar-item-work-hour-monthly-grid-selection.component";
import {ToolbarItemDownloadTemplatesComponent} from "../items/components/print-template/toolbar-item-download-templates.component";
import {ToolbarItemWorkingModelTimeDecimalSwitchComponent} from "../items/components/working-model-time-decimal-switch/toolbar-item-working-model-time-decimal-switch.component";
import {ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent} from '../items/components/working-model-time-decimal-switch/toolbar-item-selected-working-model-day-decimal-switch.component';
import {ToolbarItemUnlockFormComponent} from '../items/components/contract-of-employment/toolbar-item-unlock-form.component';
import {ToolbarItemUnlockColumnComponent} from '../items/components/Unlock-Grid-Column/toolbar-item-unlock-column.component';
import {ToolbarItemAuditComponent} from '../items/components/audit/toolbar-item-audit.component';
import {ToolbarItemCsvExportComponent} from '../items/components/csv-export/toolbar-item-csv-export.component';
import {ToolbarItemImportSftpPrinterComponent} from '../items/components/import-sftp-printer/toolbar-item-import-sftp-printer.component';
import {ToolbarItemUserImportComponent} from '../items/components/user-import/toolbar-item-user-import.component';
import {ToolbarItemMasterSaveComponent} from '../items/components/master-save/toolbar-item-master-save.component';
import {ToolbarItemRunCronjobComponent} from '../items/components/cron/toolbar-item-run-cronjob.component';
import {ToolbarEntityPreviewComponent} from '../items/components/collective-agreement/entity-preview/toolbar-entity-preview.component';
import {ToolbarItemValidateCaEntitiesComponent} from '../items/components/collective-agreement/validate-ca-entities/toolbar-item-validate-ca-entities.component';
import {ToolbarItemSaveAndCloseStepComponent} from '../items/components/workflow/save-and-close-step/toolbar-item-save-and-close-step.component';
import {ToolbarItemSaveAndTriggerWorkflowComponent} from '../items/components/save-and-trigger-workflow/toolbar-item-save-and-trigger-workflow.component';
import {ToolbarItemImportDefaultsDialog} from '../items/components/import-defaults/toolbar-item-import-defaults-dialog.component';

@Injectable()
export class ToolbarItemServiceFactory {

  private validateItemMessage = '';

  private toolbarItem;

  private pool = {
    'add': ToolbarItemAdd,
    'add_pre_calculation_articles': ToolbarItemAddPreCalculationArticleComponent,
    'import_special_allowances': ToolbarItemImportSpecialAllowancesComponent,
    'sync_articles': ToolbarItemSyncArticlesComponent,
    'addWhenComponentIsNotChanged': ToolbarItemAddWhenComponentIsNotChangedComponent,
    'addPreCalculation': ToolbarItemAddPreCalculationComponent,
    'delete_filters': ToolbarItemDeleteFilters,
    'openFilters': ToolbarItemOpenFilters,
    'edit': ToolbarItemEdit,
    'save': ToolbarItemSave,
    'masterSave': ToolbarItemMasterSaveComponent,
    'exportLga': ToolbarItemExportReportComponent,
    'savePreCalculationArticle': ToolbarItemSavePreCalculationArticleComponent,
    'resetPreCalculationArticle': ToolbarItemResetPreCalculationArticleComponent,
    'saveGroupedArticle': ToolbarItemSaveGroupedArticleComponent,
    'print': ToolbarItemPrint,
    'refresh': ToolbarItemRefresh,
    'back': ToolbarItemBack,
    'cancel': ToolbarItemCancel,
    'delete': ToolbarItemDelete,
    'validateEntities': ToolbarEntityPreviewComponent,
    'draftSave': ToolbarItemDraftSave,
    'draftDelete': ToolbarItemDraftDelete,
    'bulkAdd': ToolbarItemBulkAddComponent,
    'details': ToolbarItemDetails,
    'open_element': ToolbarItemOpenElement,
    'open_module': ToolbarItemOpenModule,
    'open_new_offer': ToolbarItemOpenNewOfferComponent,
    'open_new_order': ToolbarItemOpenNewOrderComponent,
    'open_questionnaire_by_phone_project_group': ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent,
    'generate_work_dates': ToolbarItemGenerateWorkDatesComponent,
    'open_assignments': ToolbarItemOpenModuleAssignmentsComponent,
    'softDeleteableFilter': ToolbarItemShowDeleted,
    'unDelete': ToolbarItemUnDeleteComponent,
    'expireableFilter': ToolbarItemShowExpired,
    'ignoreBranchOfficeFilter': ToolbarItemShowIgnoreBranchOfficeComponent,
    'withoutMasterFilterFieldFilter': ToolbarItemShowWithoutMasterFilterField,
    'manage_default_parameters': ToolbarItemCollectiveContractManageParameters,
    'company_search': ToolbarItemCompanySearch,
    'user_import': ToolbarItemUserImportComponent,
    'ksvCompanySearch': ToolbarItemKsvCompanySearchComponent,
    'workHourMonthlyGridShowWageAndInvoiceColumns': ToolbarItemWorkHourMonthlyGridShowWageAndInvoiceColumnsComponent,
    'workHourMonthlyGridToggleBetweenWageAndInvoiceColumns': ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent,
    'workHourMonthlyGridRecalculate' : ToolbarItemWorkHourMonthlyGridRecalculateComponent,
    'workHourMonthlyGridSelection' : ToolbarItemWorkHourMonthlyGridSelectionComponent,
    'workingModelTimeDecimalSwitchComponent' : ToolbarItemWorkingModelTimeDecimalSwitchComponent,
    'selectedWorkingModelDayDecimalSwitchComponent' : ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent,
    'copy': ToolbarItemCopy,
    'bdmExportComponent': ToolbarItemBdmExportComponent,
    'take_address': ToolbarItemTakeAddressToMasterComponent,
    'set_state_send': ToolbarItemSetOrderOfferSendStateComponent,
    'set_state_confirmed': ToolbarItemSetOrderOfferConfirmedStateComponent,
    'take_multiselected': ToolbarItemImportMultiselectedToMasterComponent,
    'importMultiselectedInModuleContext': ToolbarItemImportMultiselectedInModuleContext,
    'assign_leased_employee': ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent,
    'createLeasedEmployeeFromCV': ToolbarItemCreateLeasedEmployeeFromCvComponent,
    'updateLeasedEmployeeByDocument': ToolbarItemUpdateLeasedEmployeeByDocumentComponent,
    'showOrHideContractOfEmploymentExitInformation': ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent,
    'open_job_advertisement': ToolbarItemOpenModuleJobAdvertisementComponent,
    'audit': ToolbarItemAuditComponent,
    'open_assign_leased_employees': ToolbarItemOpenModuleAssignmentsSelectedLeasedEmployeeComponent,
    'open_skills_of_leased_employee': ToolbarItemOpenModuleSelectedSkillsOfLeasedEmployeeComponent,
    'import_skills_to_leased_employee': ToolbarItemAddSkillsToLeasedEmployeeComponent,
    'open_copy_of_contract_of_employment': ToolbarItemOpenModuleContractOfEmploymentComponent,
    'open_copy_of_contract_of_employment2': ToolbarItemOpenModuleContractOfEmployment2Component,
    'ToolbarItemStornoContractOfEmployment': ToolbarItemStornoContractOfEmploymentComponent,
    'ToolbarItemStornoInvoice': ToolbarItemStornoInvoiceComponent,
    'reorder': ToolbarItemReorderColumns,
    'ca_wage_calculation': ToolbarItemCaWageCalculationComponent,
    'ca_workhour_flatten': ToolbarItemCaWorkhourFlattenComponent,
    'ca_workhour_unflatten': ToolbarItemCaWorkhourUnflattenComponent,
    'ca_workhour_invoice_flatten': ToolbarItemCaWorkhourInvoiceFlattenComponent,
    'ca_workhour_invoice_unflatten': ToolbarItemCaWorkhourInvoiceUnflattenComponent,
    'sync_salary_types': ToolbarItemSyncSalaryTypesComponent,
    'calculation_result': ToolbarItemCalculationResultComponent,
    'caContractOfEmployment': ToolbarItemCaContractOfEmploymentComponent,
    'caBulkCalculate': ToolbarItemCaBulkCalculateComponent,
    'templateUpload': ToolbarItemUploadDocument,
    'leasedEmployeeDocumentUpload': ToolbarItemUploadLeasedEmployeeDocumentComponent,
    'templateXmlUpload': ToolbarItemUploadXmlDocumentComponent,
    'templateGkkUpload': ToolbarItemUploadGkkDocumentComponent,
    'reportExport': ToolbarReportExportComponent,
    'csvExport': ToolbarItemCsvExportComponent,
    'wordexport': ToolbarItemWordExport,
    'downloadtemplates': ToolbarItemDownloadTemplatesComponent,
    'importCloudPrinter': ToolbarItemImportCloudPrinterComponent,
    'importSftpPrinter': ToolbarItemImportSftpPrinterComponent,
    'preCalculationArticleFactors': ToolbarItemPreCalculationShowFactorsComponent,
    'releasePreCalculationComponent': ToolbarItemReleasePreCalculationComponent,
    'wordExportConfig': ToolbarItemWordExportConfiguration,
    'open_link': ToolbarItemOpenLink,
    'UnlockColumn': ToolbarItemUnlockColumnComponent,
    'toolbarItemUnlockForm': ToolbarItemUnlockFormComponent,
    'bulk_download_docuware': ToolbarItemDownloadBulkDocuwareComponent,
    'send_sms': ToolbarItemSendSms,
    'openImportEntityDialog': ToolbarItemEntityImportDialog,
    'openImportDefaultsDialog': ToolbarItemImportDefaultsDialog,
    'importEntity': ToolbarItemEntityImport,
    'openSalaryTypeImportDialog': ToolbarItemSalaryTypeImportDialog,
    'splitSalaryType': ToolbarItemSalaryTypeSplit,
    'salaryTypeCreateArticlesFromSelected': ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent,
    'toolbarItemPhoneProjectCreator': ToolbarItemPhoneProjectCreatorComponent,
    'executeStep': ToolbarItemExecuteStepComponent,
    'expertSearch': ToolbarItemExpertSearchComponent,
    'simpleSearch': ToolbarItemSimpleSearchComponent,
    'openQuickLink': ToolbarItemOpenQuickLinkComponent,
    'saveAndTriggerWorkflow': ToolbarItemSaveAndTriggerWorkflowComponent,

    'form_cancel': ToolbarItemFormCancel,
    'form_new': ToolbarItemFormNew,
    'form_next': ToolbarItemFormNext,
    'form_previous': ToolbarItemFormPrevious,
    'form_last': ToolbarItemFormLast,
    'form_first': ToolbarItemFormFirst,
    'form_counter': ToolbarItemFormCounter,
    'form_add_address': ToolbarItemFormAddAddress,
    'runCronjob' : ToolbarItemRunCronjobComponent,
    'run_ca_validation_cycle': ToolbarItemValidateCaEntitiesComponent,
    'workflow_save_and_close_step': ToolbarItemSaveAndCloseStepComponent,
  };

  private parentComponent;
  private factory;

  public setParentComponent(parentComponent) {
    this.parentComponent = parentComponent;

    return this;
  }

  constructor(
    private componentFactory: ComponentFactoryResolver
  ) { }

  public get(item: ToolbarItem) {
    this.toolbarItem = item;

    if (!this.validateItem(item)) {
      throw Error(this.validateItemMessage);
    }

    this.factory = this.resolveFactory(item);

    return this;
  }

  private resolveFactory(item) {
    let factory = this.componentFactory.resolveComponentFactory(this.pool[item.onclick]);

    if ((item.onclick == 'add' || item.onclick == 'edit') && item.targetObject) {
      factory = this.componentFactory.resolveComponentFactory(this.pool[item.targetObject]);
    }

    return factory;
  }

  private validateItem(item) {

    if (!item.onclick) {
      this.validateItemMessage = 'Item onclick not implemented';

      return false;
    } else if (!this.pool[item.onclick]) {
      this.validateItemMessage = `Item onclick for ${item.onclick} not configured, assign it to the pool, entryComponents, sharedDeclarations`;

      alert(`${item.onclick} not yet implemented`);

      return false;
    } else if (!this.parentComponent || !this.parentComponent.viewContainerRef) {
      this.validateItemMessage = `Parent Component or it's viewContainerRef not set, use in Parent constructor(viewContainerRef)!`;

      return false;
    }

    return true;
  }

  public click() {
    let instance = this.parentComponent.viewContainerRef.createComponent(this.factory).instance;

    // pass the Inputs here
    instance.params = this.parentComponent.params;
    instance.params.toolbarItem = this.toolbarItem;

    instance.click();
  }

  public isItemCheckboxEnabled() {
    let instance = this.parentComponent.viewContainerRef.createComponent(this.factory).instance;

    // pass the Inputs here
    instance.params = this.parentComponent.params;
    instance.params.toolbarItem = this.toolbarItem;

    return instance.isItemCheckboxEnabled();
  }

  public getText() {
    let instance = this.parentComponent.viewContainerRef.createComponent(this.factory).instance;

    // pass the Inputs here
    instance.params = this.parentComponent.params;
    instance.params.toolbarItem = this.toolbarItem;

    return instance.getText();
  }
}
