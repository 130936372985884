import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {ElementState} from "./element-state";
import {ElementType} from "./ElementContext";

@Injectable()
export class ElementsStateService {

    /**
     * the elements stack.
     */
    private elementStates: ElementState[];

    /**
     *
     */
    public constructor() {
        this.elementStates = [];
    }

    /**
     *
     * @returns {ElementState[]}
     */
    public getAll(): ElementState[] {
        return this.elementStates;
    }
    
    public add(elementState: ElementState): ElementsStateService {
        if (!this.exists(elementState)) {
            this.elementStates.push(elementState);
        }

        return this;
    }

    /**
     *
     * @param {ElementState} elementState
     * @returns {ElementsStateService}
     */
    public remove(elementState: ElementState): ElementsStateService {
        if (elementState && this.exists(elementState)) {
            let elementStatesId = this.elementStates.findIndex((element) => {
                return elementState.id == element.id && elementState.elementType == element.elementType;
            });

            if (elementStatesId != -1) {
                this.elementStates.splice(elementStatesId, 1);
            }
        }

        return this;
    }

    /**
     *
     * @param {number} moduleId
     * @returns {ElementState[]}
     */
    public findByModuleId(moduleId: number): ElementState[] {
        return this.elementStates.filter((elementState) => { return elementState.moduleElement && elementState.moduleElement.moduleId === moduleId});
    }

    /**
     *
     * @param {number} moduleId
     * @returns {ElementsStateService}
     */
    public removeByModuleId(moduleId: number): ElementsStateService {
        let states = this.findByModuleId(moduleId);

        for (let state of states) {
            this.remove(state);
        }

        return this;
    }

  /**
   *
   * @param {number} moduleElementId
   * @returns {ElementsStateService}
   */
  public removeById(moduleElementId: number): ElementsStateService {
    const state = this.findById(moduleElementId);

    if (state) {
      this.remove(state);
    }

    return this;
  }

    /**
     *
     * @param {ElementState} elementState
     * @returns {boolean}
     */
    public exists(elementState: ElementState): boolean {
        return this.findByIdAndType(elementState.id, elementState.elementType) != null;
    }

    /**
     *
     * @param {number} id
     * @returns {ElementState}
     */
    public findById(id: number): ElementState {
        for (let state of this.elementStates) {
            if (state.id == id) {
                return state;
            }
        }

        return null;
    }

    /**
     *
     * @param {number} id
     * @param {ElementType} type
     * @returns {ElementState}
     */
    public findByIdAndType(id: number, type: ElementType): ElementState {
        for (let state of this.elementStates) {
            if (state.id == id && state.elementType == type) {
                return state;
            }
        }

        return null;
    }

    /**
     *
     * @param {number} id
     * @param entity
     * @returns {ElementsStateService}
     */
    public setSelectedEntity(id: number, entity: any): ElementsStateService {
        let state = this.findById(id);
        state.setSelectedEntity(entity);

        return this;
    }
}
