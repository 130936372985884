import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, Self} from '@angular/core';
import {AutoComplete} from 'primeng/primeng';
import {Subject} from 'rxjs';
import {ChangeDetectorRefHelper} from '../helpers/change-detector-ref.helper';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {GenericCrudService} from '../services/generic-crud.service';
import {Entity} from '../helpers/entity';

export interface AutocompleteFilter {
  name: string;
  value: string;
}

@Directive({
  selector: '[appGenericAutoCompleteHandler]'
})
export class GenericAutocompleteHandlerDirective implements OnInit, OnDestroy {

  @Input() public apiRoute: string = null;
  @Input() public labelField = 'name';
  @Input() public valueField = 'id';
  @Input() public paginated = false;
  @Input() public searchField = 'name';
  @Input() public filters: AutocompleteFilter[] = [];
  @Input() public set value(value: number) {
    if (value && this.autocomplete) {
      this.autocomplete.suggestions = this.autocomplete.suggestions || [];
      let suggestion = this.autocomplete.suggestions.find((aItem) => {
        return aItem.value === value;
      });

      if (suggestion) {
        this.selectSuggestion(suggestion)
      } else {
        this.genericCrudService.getEntity(this.apiRoute, value)
          .pipe(
            debounceTime(200),
            takeUntil(this.unsubscribe)
          )
          .subscribe((aValue) => {
            suggestion = this.getSuggestion(aValue)
            this.addSuggestion(suggestion)
              .selectSuggestion(suggestion);
          })
      }
    }
  };

  public unsubscribe = new Subject<void>();

  public autocomplete: AutoComplete = null;

  constructor(
    @Self() autocomplete: AutoComplete,
    public cdr: ChangeDetectorRef,
    protected genericCrudService: GenericCrudService
  ) {
    this.autocomplete = autocomplete;

    if (!(this.autocomplete instanceof AutoComplete)) {
      console.error('AutoCompleteDirective works only with p-autocomplete component!');
    }
  }

  public ngOnInit(): void {
    this.autocomplete.completeMethod.subscribe((event: any) => {
      this.onComplete(event);
    });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onComplete(event): void {
    const query = event.query;
    let apiRoute = this.apiRoute;

    if (this.apiRoute === null) {
      console.error('ApiRoute missing in Host component, pass it as @Input()!');
    }

    if (this.paginated) {
      apiRoute += '/offset/0/limit/50/orderby/id/asc';
    }

    const requestParams: Record<string, any> = {};

    if (this.searchField && query) {
      requestParams[this.searchField] = `contains:${query}`;
    }

    if (!this.searchField) {
      requestParams.search = query;
    }

    for (const filterField of this.filters) {
      requestParams[filterField.name] = filterField.value;
    }

    this.genericCrudService.getEntities(apiRoute, '', requestParams)
      .pipe(
        map((paginated: {data: any[]}) => {
          const data: {label: string, value: any}[] = [];

          for (const item of paginated.data) {
            data.push(this.getSuggestion(item));
          }

          return data;
        }),
        takeUntil(this.unsubscribe)
      ).subscribe((data: any[]) => {
      this.autocomplete.suggestions = data;

      ChangeDetectorRefHelper.detectChanges(this);
    })
  }

  public selectSuggestion(suggestion: {label: string, value: string}) {
    this.autocomplete.selectItem(suggestion);
  }

  private addSuggestion(suggestion: {label: string, value: string}) {
    const index = this.autocomplete.suggestions.findIndex((aItem) => {
      return aItem.value === suggestion.value;
    });

    if (index === -1) {
      this.autocomplete.suggestions = [...this.autocomplete.suggestions, suggestion]
    }

    return this;
  }

  private getSuggestion(item: Record<string, any>) {
    let label = item[this.labelField]

    if (this.labelField.includes('{')) {
      label = Entity.getValueFromPlaceholder(item, this.labelField)
    }

    return {label: label, value: this.valueField ? item[this.valueField] : item, entity: item }
  }
}
