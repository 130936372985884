
import {of as observableOf,  Observable } from 'rxjs';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';
import { EntityStatus } from '../../entity/entity-status';
import { ComponentValidationExecutionStep } from './component-validation-execution-step';

export class ComponentValidationOnSaveExecutionStep extends ComponentValidationExecutionStep {

  protected doValidation(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const entity = this.getEntity(component);

    if (null === entity || entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
      return observableOf({status: true, content: null});
    }

    if (entity[EntityStatus.ENTITY_VALIDATED_FLAG] && !entity[EntityStatus.ENTITY_INVALID_FLAG]) {
      return observableOf({status: true, content: null});
    }

    return super.doValidation(component);
  }
}
