
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../../../core/message/message-growl.service';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {ArticleFieldRegistry} from '../../../../article/article-field.registry';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {PreCalculationArticleCalculatorFactory} from '../../../../precalculation/article/calculation/type/pre-calculation-article-calculator.factory';
import {PreCalculation, PreCalculationArticle} from '../../../../precalculation/article/pre-calculation-article';
import {PreCalculationArticleRecalculateService} from '../../../../precalculation/article/calculation/pre-calculation-article-recalculate.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

export class PrecalculationDetailsRecalculateOtherArticleStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    let component = payload.getValue();

    if (payload instanceof Object && payload.getValue().component) {
      component = payload.getValue().component;
    }

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    const entityChangeMetaData = payload.getValue().entityDataChangeMeta;

    if (!entityChangeMetaData || !entityChangeMetaData.entity || !this.hasChangeHappened(entityChangeMetaData)) {
      return observableOf({status: true, content: null});
    }

    const minimalCalculatedSalary = entityChangeMetaData.entity['calculatedSalary'] ?
      entityChangeMetaData.entity['calculatedSalary'] : entityChangeMetaData.entity['initialSalaryHour'];

    if (minimalCalculatedSalary && entityChangeMetaData.entity['salaryHour'] < minimalCalculatedSalary) {
      const growlService = this.injector.get(MessageGrowlService, null);
      growlService.info('salary can not be smaller then the calculated salary.', 'Salary error');
      entityChangeMetaData.entity['salaryHour'] = minimalCalculatedSalary;
    }

    const changedField = entityChangeMetaData.gridField.id;

    if (entityChangeMetaData.entity[ArticleFieldRegistry.FIELD_NAME_IS_INVOICE_MANUALLY_CHANGED]
      && changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_HOUR) {

      const confirmationService = this.injector.get(ConfirmationService, null);
      const translationService = this.injector.get(TranslateService, null);

      confirmationService.confirm({
        acceptVisible: true,
        header: translationService.instant('DIALOG_MESSAGES.CHANGED_INVOICE_HOUR_HEADER'),
        message: translationService.instant('DIALOG_MESSAGES.CHANGED_INVOICE_HOUR_BODY'),
        icon: 'fa fa-update',
        accept: () => {
          entityChangeMetaData.entity[ArticleFieldRegistry.FIELD_NAME_IS_INVOICE_MANUALLY_CHANGED] = false;
          this.doCalculateEntity(component, entityChangeMetaData.entity, true, changedField);
        },
        reject: () => {
          this.doCalculateEntity(component, entityChangeMetaData.entity, false, changedField);
        }
      });
    } else {
      this.doCalculateEntity(component, entityChangeMetaData.entity, true, changedField);
    }

    return this.getFailObservable('Grid not found!');
  }

  /**
   *
   * @param grid
   * @param changedPreArticleCalculation
   * @param recalculateInvoice
   * @param changedField
   */
  private doCalculateEntity(
    grid: AbstractGenericGridComponent,
    changedPreArticleCalculation: PreCalculationArticle,
    recalculateInvoice: boolean,
    changedField: string): Observable<ExecutionStepStatus> {

    grid.isDataLoading = true;
    const precalculation = this.getPreCalculation(grid);

    if (changedField !== ArticleFieldRegistry.FIELD_NAME_IS_ACTIVE) {
      changedPreArticleCalculation.isActive = true;
    }

    if (!changedPreArticleCalculation.isUsingFactorIncrement) {
      this.recalculateRegularArticle(changedPreArticleCalculation, changedField, recalculateInvoice);
    } else {
      this.recalculateSpecialArticle(changedPreArticleCalculation, changedField, grid);
    }

    changedPreArticleCalculation.isFactorUnderMinimal = changedPreArticleCalculation.factor < changedPreArticleCalculation.factorInvoiceMin;

    const calculatorFactory = this.injector.get(PreCalculationArticleCalculatorFactory, null);

    const generalArticlesGrid = this.getGeneralArticlesGrid(grid);
    if (generalArticlesGrid && calculatorFactory && this.checkChangedArticle(changedPreArticleCalculation)) {
      const entity = generalArticlesGrid.getSelectedEntity();
      entity.isManuallyChangedInvoice = false;

      // First reset the entity from the grid:
      const calculatorGeneral = calculatorFactory.spawnCalculator(entity);
      calculatorGeneral.calculate(entity, [], precalculation);

      // Now let's recalculate the values:
      const otherArticles = grid.getEntities();

      for (const otherArticle of otherArticles) {
        const calculator = calculatorFactory.spawnCalculator(otherArticle);
        calculator.calculate(otherArticle, [entity], precalculation);

        generalArticlesGrid.getInlineEditService().markForCheck(entity);
      }

      const recalculationService = this.injector.get(PreCalculationArticleRecalculateService, null);
      recalculationService.recalculateArticles(entity, otherArticles, precalculation);
      ChangeDetectorRefHelper.detectChanges(grid);
    }

    grid.isDataLoading = false;

    return observableOf({status: true, content: null});
  }

  private checkChangedArticle(otherArticle: PreCalculationArticle): boolean {
    return !otherArticle.isUsingFactorIncrement;
  }

  private recalculateRegularArticle(
    changedPreArticleCalculation: PreCalculationArticle,
    changedField: string,
    recalculateInvoice: boolean): void {

    if ((changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_HOUR || changedField === ArticleFieldRegistry.FIELD_NAME_FACTOR)
      && recalculateInvoice) {
      changedPreArticleCalculation.invoiceHour = changedPreArticleCalculation.salaryHour * changedPreArticleCalculation.factor;
      changedPreArticleCalculation.targetInvoiceHour = changedPreArticleCalculation.invoiceHour;
    } else if (changedField === ArticleFieldRegistry.FIELD_NAME_INVOICE_HOUR) {
      changedPreArticleCalculation.isManuallyChangedInvoice = true;
      changedPreArticleCalculation.factor = changedPreArticleCalculation.invoiceHour / changedPreArticleCalculation.salaryHour;
    }
  }

  private recalculateSpecialArticle(
    changedPreArticleCalculation: PreCalculationArticle,
    changedField: string,
    grid: AbstractGenericGridComponent): void {

    // Force it to false - as it is not possible to set it to true for these types of articles:
    changedPreArticleCalculation.isIncludedInNormalWorkhours = false;
    if (changedField === ArticleFieldRegistry.FIELD_NAME_INVOICE_HOUR || changedField === ArticleFieldRegistry.FIELD_NAME_FACTOR) {
      const genericGrid = this.getGeneralArticlesGrid(grid);

      if (genericGrid) {
        const generalArticle = genericGrid.getSelectedEntity();

        if (generalArticle) {
          const invoiceGeneralArticle = generalArticle.invoiceHour;

          if (changedField === ArticleFieldRegistry.FIELD_NAME_FACTOR) {
            changedPreArticleCalculation.invoiceHour = invoiceGeneralArticle * changedPreArticleCalculation.factor;
            changedPreArticleCalculation.targetInvoiceHour = changedPreArticleCalculation.invoiceHour;
          } else {
            changedPreArticleCalculation.factor = changedPreArticleCalculation.invoiceHour / invoiceGeneralArticle;
          }
        }
      }
    }
  }

  private getPreCalculation(grid: AbstractGenericGridComponent): PreCalculation|null {
    let masterForm = grid.getElementContext().getMasterElementContext().component;

    if (masterForm instanceof AbstractGenericGridComponent) {
      masterForm = masterForm.getElementContext().getMasterElementContext().component;
    }

    if (!(masterForm instanceof FormViewerComponent)) {
      return null;
    }

    return masterForm.getSelectedEntity();
  }

  private getGeneralArticlesGrid(grid: AbstractGenericGridComponent): AbstractGenericGridComponent|null {
    if (grid.getElementContext().getMasterElementContext().component instanceof AbstractGenericGridComponent) {
      return grid.getElementContext().getMasterElementContext().component;
    }

    return null;
  }

  protected hasChangeHappened(entityChangeMetaData: any): boolean {
    const changedField = entityChangeMetaData.gridField.id;
    if ((changedField === ArticleFieldRegistry.FIELD_NAME_INVOICE_HOUR || changedField === ArticleFieldRegistry.FIELD_NAME_INVOICE_MONTH
      || changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_HOUR || changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_MONTH
      || changedField === ArticleFieldRegistry.FIELD_NAME_FACTOR || changedField === ArticleFieldRegistry.FIELD_NAME_AMOUNT
      || changedField === ArticleFieldRegistry.FIELD_NAME_WEEKLY_HOURS || changedField === ArticleFieldRegistry.FIELD_NAME_DESCRIPTION)
      && entityChangeMetaData.oldValue === entityChangeMetaData.value) {
      return false;
    }
    return true;
  }
}
