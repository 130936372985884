import {Injectable, QueryList} from '@angular/core';
import {ExecutionStep} from '../execution-step';
import {ModuleState} from '../../../shared/content-renderer/services/module-state';
import {ExecutionStepPayload} from '../execution-step-payload';
import {GenericElementAbstract} from '../../../shared/content-renderer/elements/generic-element-abstract.component';
import {MasterEntityImportSubEntitiesExecutionStep} from '../../../shared/services/execution-step/master-entity-import-sub-entities-execution.step';
import {ComponentRefreshExecutionStep} from '../../../shared/services/execution-step/component-refresh-execution-step';
import {ComponentSaveExecutionStep} from '../../../shared/services/execution-step/component-save-execution-step';
import {ComponentSlaveIndependantSaveExecutionStep} from '../../../shared/services/execution-step/component-slave-independant-save-execution-step';
import {ModuleStateInspectorService} from '../service/module-state-inspector.service';
import {DetailsViewMasterSaveExecutionStep} from '../../../shared/services/execution-step/details-view/details-view-master-save-execution.step';
import {DetailsViewMasterEntitySetupExecutionStep} from '../../../shared/services/execution-step/details-view/details-view-master-entity-setup-execution-step';
import {DetailsViewMasterEntityValidationExecutionStep} from '../../../shared/services/execution-step/details-view/details-view-master-entity-validation-execution-step';
import {DetailsViewMasterRefreshExecutionStep} from '../../../shared/services/execution-step/details-view/details-view-master-refresh-execution.step';
import {ModuleEntityDeleteExecutionStep} from '../../../shared/services/execution-step/module/module-entity-delete-execution.step';
import {ExecutionStepFactoryService} from '../factory/execution-step-factory.service';
import {ElementContext, ElementType} from '../../../shared/content-renderer/services/ElementContext';
import {ExecutionWorkflowBuilderInterface} from './execution-workflow-builder.interface';
import {DetailsViewMasterAfterSaveExecutionStep} from '../../../shared/services/execution-step/details-view/details-view-master-after-save-execution.step';
import {EntitySaveExecutionStep} from '../../../shared/services/execution-step/entity-save-execution-step';
import {DetailsViewMasterCloseDialogExecutionStep} from '../../../shared/services/execution-step/details-view/details-view-master-close-dialog-execution-step';
import {ComponentCloseDialogExecutionStep} from '../../../shared/services/execution-step/component-close-dialog-execution-step';
import {ModulesStateService} from '../../../shared/content-renderer/services/modules-state.service';
import {ComponentValidationOnSaveExecutionStep} from '../../../shared/services/execution-step/validation/component-validation-on-save-execution-step';

@Injectable()
export class ExecutionStepBuilderService implements ExecutionWorkflowBuilderInterface {

  doCreateSaveSteps = true;
  doCreateValidationSteps = true;
  doSaveSingleEntity = false;
  singleEntity = null;

  /**
   * @param {ModuleStateInspectorService} moduleStateInspectorService
   * @param {ExecutionStepFactoryService} executionStepFactoryService
   */
  constructor(
    protected moduleStateInspectorService: ModuleStateInspectorService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected modulesStateService: ModulesStateService
  ) {}

  /**
   * @param {boolean} createStep
   * @returns {ExecutionStepBuilderService}
   */
  public setDoCreateSaveSteps(createStep: boolean): ExecutionStepBuilderService {
    this.doCreateSaveSteps = createStep;
    return this;
  }

  /**
   * @param {boolean} createStep
   * @returns {ExecutionStepBuilderService}
   */
  public setDoCreateValidationSteps(createStep: boolean): ExecutionStepBuilderService {
    this.doCreateValidationSteps = createStep;
    return this;
  }

  /**
   * @param {boolean} doSaveSingleEntity
   * @returns {ExecutionStepBuilderService}
   */
  public setDoSaveSingleEntityStep(doSaveSingleEntity: boolean): ExecutionStepBuilderService {
    this.doSaveSingleEntity = doSaveSingleEntity;
    return this;
  }

  /**
   * @param {boolean} singleEntity
   * @returns {ExecutionStepBuilderService}
   */
  public setSingleEntity(singleEntity: any): ExecutionStepBuilderService {
    this.singleEntity = singleEntity;
    return this;
  }

  /**
   *
   * @param {ModuleState} currentModuleContext
   * @returns {ExecutionStep[]}
   */
  public createExecutionSteps(currentModuleContext: ModuleState): ExecutionStep[] {
    let executionSteps = [];

    if (currentModuleContext.isDetailsView) {
      executionSteps = this.createBasedOnDetailsView(currentModuleContext);
    } else {
      this.createCompleteStructure(currentModuleContext, true, executionSteps);
    }

    return executionSteps;
  }

  protected createBasedOnMasterComponents(masterComponents: GenericElementAbstract[], doSave: boolean = true): ExecutionStep[] {
    const executionSteps = [],
      currentModuleContext = this.modulesStateService.getCurrent();

    for (const masterComponent of masterComponents) {
      const payload = new ExecutionStepPayload(masterComponent);

      executionSteps.push(
        this.executionStepFactoryService.create(MasterEntityImportSubEntitiesExecutionStep, payload)
      );

      // todo:: use cached
      if (this.doCreateValidationSteps) {
        executionSteps.push(
          this.executionStepFactoryService.create(ComponentValidationOnSaveExecutionStep, payload)
        );

        for (const slaveElementContext of this.getSlaveComponentsWithChanges(masterComponent)) {
          executionSteps.push(
            this.executionStepFactoryService.create(
              ComponentValidationOnSaveExecutionStep,
              new ExecutionStepPayload(slaveElementContext.component)
            )
          );
        }
      }

      for (const slaveElementContext of this.getSlaveComponentsWithChanges(masterComponent)) {
        if (this.isDynamicComponent(slaveElementContext)) {
          this.createComponentStructure(slaveElementContext.component, executionSteps);
        }
      }

      if (doSave) {
        const executionStepSave = new ComponentSaveExecutionStep();
        executionSteps.push(
          this.executionStepFactoryService.create(ComponentSaveExecutionStep, payload)
        );

        for (const slaveElementContext of this.getSlaveComponentsWithChanges(masterComponent)) {
          const executionStepSaveSlavePayload = new ExecutionStepPayload(slaveElementContext.component);

          if (slaveElementContext.type === ElementType.Grid ||
            slaveElementContext.type === ElementType.Tree
          ) {
            executionSteps.push(
              this.executionStepFactoryService.create(ComponentSlaveIndependantSaveExecutionStep, executionStepSaveSlavePayload)
            );
            executionSteps.push(
              this.executionStepFactoryService.create(ComponentRefreshExecutionStep, executionStepSaveSlavePayload)
            );
          }
        }

        if (currentModuleContext.isDialog) {
          executionSteps.push(
            this.executionStepFactoryService.create(ComponentCloseDialogExecutionStep, payload)
          );
        } else {
          executionSteps.push(
            this.executionStepFactoryService.create(ComponentRefreshExecutionStep, payload)
          );
        }
      }
    }

    return executionSteps;
  }

  protected createBasedOnRegularComponents(components: GenericElementAbstract[]): ExecutionStep[] {
    const executionSteps = [];

    for (const component of components) {
      this.createComponentStructure(component, executionSteps);
    }

    return executionSteps;
  }

  protected createBasedOnDetailsView(currentModuleContext: ModuleState): ExecutionStep[] {
    const executionSteps = [],
      detailsMasterComponent =  this.moduleStateInspectorService.getDetailsMasterComponent(currentModuleContext);

    this.createCompleteStructure(currentModuleContext, false, executionSteps);
    this.createExpanderSteps(currentModuleContext, executionSteps);

    executionSteps.push(
      this.executionStepFactoryService.create(DetailsViewMasterEntitySetupExecutionStep, new ExecutionStepPayload(currentModuleContext))
    );

    if (detailsMasterComponent) {
      executionSteps.push(
          this.executionStepFactoryService.create(ComponentValidationOnSaveExecutionStep, new ExecutionStepPayload(detailsMasterComponent))
      );
     }

    executionSteps.push(
      this.executionStepFactoryService.create(
        DetailsViewMasterEntityValidationExecutionStep,
        new ExecutionStepPayload(currentModuleContext)
      )
    );

    executionSteps.push(
      this.executionStepFactoryService.create(DetailsViewMasterSaveExecutionStep, new ExecutionStepPayload(currentModuleContext))
    );

    executionSteps.push(
      this.executionStepFactoryService.create(DetailsViewMasterAfterSaveExecutionStep, new ExecutionStepPayload(currentModuleContext))
    );

    executionSteps.push(
      this.executionStepFactoryService.create(ModuleEntityDeleteExecutionStep, new ExecutionStepPayload(currentModuleContext))
    );

    if (currentModuleContext.isDialog) {
      executionSteps.push(
        this.executionStepFactoryService.create(DetailsViewMasterCloseDialogExecutionStep, new ExecutionStepPayload(currentModuleContext))
      );
    } else {
      executionSteps.push(
        this.executionStepFactoryService.create(DetailsViewMasterRefreshExecutionStep, new ExecutionStepPayload(currentModuleContext))
      );
    }

    return executionSteps;
  }

  protected createCompleteStructure(currentModuleState: ModuleState,
                                    doSave: boolean,
                                    executionSteps: ExecutionStep[] = []): ExecutionStep[] {
    const masterComponents = this.moduleStateInspectorService.getModuleComponents(currentModuleState, true, true, true);
    for (const step of this.createBasedOnMasterComponents(masterComponents, doSave)) {
      executionSteps.push(step);
    }

    const independantComponents = this.moduleStateInspectorService.getModuleComponents(currentModuleState, true, true, false);

    if (doSave) {
      for (const step of this.createBasedOnRegularComponents(independantComponents)) {
        executionSteps.push(step);
      }
    } else if (!doSave && this.doCreateValidationSteps) {
      for (const component of independantComponents) {
        executionSteps.push(
          this.executionStepFactoryService.create(ComponentValidationOnSaveExecutionStep, new ExecutionStepPayload(component))
        );
      }
    }

    for (const part of currentModuleState.getParts()) {
      this.createCompleteStructure(part, doSave, executionSteps);
    }

    return executionSteps;
  }

  private createComponentStructure(component: GenericElementAbstract, executionSteps: ExecutionStep[]): ExecutionStep[] {
    const payload = new ExecutionStepPayload(component),
      currentModuleContext = this.modulesStateService.getCurrent();

    if (this.doCreateValidationSteps) {
      executionSteps.push(
        this.executionStepFactoryService.create(ComponentValidationOnSaveExecutionStep, payload)
      );
    }

    if (this.doSaveSingleEntity) {
      executionSteps.push(
        this.executionStepFactoryService.create(EntitySaveExecutionStep, new ExecutionStepPayload(this.singleEntity))
      );
    } else {
      executionSteps.push(
        this.executionStepFactoryService.create(ComponentSaveExecutionStep, payload)
      );
    }

    if (currentModuleContext.isDialog) {
      executionSteps.push(
        this.executionStepFactoryService.create(ComponentCloseDialogExecutionStep, payload)
      );
    } else {
      executionSteps.push(
        this.executionStepFactoryService.create(ComponentRefreshExecutionStep, payload)
      );
    }

    return executionSteps;
  }

  private getSlaveComponentsWithChanges(masterComponent: GenericElementAbstract): ElementContext[] {
    const slaves = [];

    for (const slave of masterComponent.getElementContext().getSlaveElementContexts()) {
      if (slave.component.hasChanges(false)) {
        slaves.push(slave);
      }
    }

    return slaves;
  }

  private isDynamicComponent(context: ElementContext): boolean {
    return context.type === ElementType.DynamicGrid || context.type === ElementType.DynamicTree;
  }

  private createExpanderSteps(moduleContext: ModuleState, executionSteps: ExecutionStep[] = []): ExecutionStep[] {
    const components: any[] = moduleContext.getComponents();

    for (const component of components) {
      if (component.table && component.table.expanderComponents) {
        const expanderComponents: QueryList<any> = component.table.expanderComponents;

        expanderComponents.forEach((expanderComponent) => {
          if (expanderComponent instanceof GenericElementAbstract) {
            const payload = new ExecutionStepPayload(expanderComponent)

            executionSteps.push(
              this.executionStepFactoryService.create(ComponentSaveExecutionStep, payload)
            );
          }
        });
      }
    }

    return executionSteps;
  }
}
