import {ElementRef, Input} from '@angular/core';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {ModuleElementColumn} from '../../../../services/module/module-element-column';
import {TreeTable} from 'primeng/primeng';
import {GenericGridComponent} from '../generic-grid.component';
import {Table} from 'primeng/table';

export abstract class AbstractGridColumnComponent  {

  @Input() node: TreeNode = null;

  @Input() entity: Record<string, any> = null;

  @Input() component: AbstractGenericGridComponent = null;

  @Input() column: ModuleElementColumn = null;

  public constructor(
    protected elementRef: ElementRef
  ) {

  }

  protected findConfig(configName: string): any|null {
    for (const config of this.column.config) {
      if (config.name === configName) {
        return config;
      }
    }

    return null;
  }

  public isEditMode(): boolean {
    const table = this.component.grid,
      td = this.elementRef.nativeElement.closest('td');

    if (table instanceof TreeTable && td) {
      return typeof table.editingCell !== 'undefined' && table.editingCell === td;
    }

    if (table instanceof Table && td) {
      return typeof table.editingCell !== 'undefined' && table.editingCell === td;
    }

    return false;
  }

  protected getConfigValue(configName: string): any|null {
    const config = this.findConfig(configName);

    return config && config.value ? config.value : null;
  }
}
