import { Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {SetCustomerEntityForInquiryJob} from '../../../job-runner/job/set-customer-entity-for-inquiry.job';
import {SetCustomerEntityForInquiryCondition} from '../../../job-runner/condition/set-customer-entity-for-inquiry.condition';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';

export class RegisterInquiryDetailsJobStep extends AbstractExecutionStep {

    doExecute(): Observable<ExecutionStepStatus> {
        const component = this.getPayload().getValue();

        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }

        return this.doRegister(component);
    }

    private doRegister(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
      return Observable.create((observer) => {

        const condition = new SetCustomerEntityForInquiryCondition();
        const customerStrippedEntity = component.selectedMasterEntity;

        this.genericCrudService.get('phoenix/customers/' + customerStrippedEntity.id)
          .subscribe((customerEntity) => {
            if (customerEntity) {
              condition.setPayload({component: component, entity: customerEntity});

              const entityManager = this.injector.get(EntityManagerService, null);

              const job = new SetCustomerEntityForInquiryJob(entityManager);
              job.setIsImmutable(true);
              this.jobContainerService.registerJob(job, condition);
            }

            observer.next({
              status: true,
              content: null
            });
            observer.complete();
          });
      });
    }
}
