
import {refCount, map, publishReplay, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Organisation } from './organisation';
import { AppHttpService } from '../../../app-http.service';
import { Constants } from 'app/constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class OrganisationCrudService extends AppHttpService {

  private organisationsUrl: string = `/${Constants.APP_API_ROUTE}/organisations`;
  private url: string = '';

  constructor(
    private http: HttpClient
  ) {
    super();
    this.url = this.getApiUrl() + this.organisationsUrl;
  }

  getOrganisations(): Observable<Organisation[]> {
    return this.http.get(this.url).pipe(
      map(this.mapResponseAsJson),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  getUserOrganisations(email): Observable<any[]> {
    return this.http.get(this.getBaseUrl() + '/users/organisations/' + email).pipe(
      map(this.mapResponseAsJson),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }
}
